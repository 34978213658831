import React from "react"
import Togglelist from "./itsComponents/Togglelist"
import PropTypes from "prop-types"

type Props = {
  bgColor?: boolean;
}

const FAQ: React.FC<Props> = ({bgColor = false}) => {
	return (
		<section className={`${bgColor ? "bg-grey" : "bg-white"} ${process.env.NEXT_PUBLIC_STOREFRONT !== "mysofaplan" && "pt-5 mt-3"} pt-md-5 pt-md-3 pb-5`}>
			<div className="container">
				<div className="row justify-content-center">
					<Togglelist/>
				</div>
			</div>
		</section>
	)
}

FAQ.propTypes = {
	bgColor: PropTypes.bool
}

export default FAQ
