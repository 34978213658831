/* eslint-disable max-params */
/* eslint-disable complexity */
import urlParams from "../../utility/UrlParams"
import {defaultValue} from "./Config"
import slugify from "slugify"
import {CollectionName} from "../../constants"

const defaultUrl = {
	mysofaplan: `/${"alle-produkte-schweiz"}/${"kategorie"}/[category]/${"marke"}/[brand]/preisbereich/[priceRange]/verfugbar/[availability]/${"sortieren"}/${"preis"}/[sortOrder]`,
	mybikeplan: `/${"alle-modelle-e-bike-schweiz"}/${"kategorie"}/[category]/${"rahmenvarianten"}/[frameVariants]/${"geschwindigkeit"}/[speed]/${"marke"}/[brand]/${"motor-marke"}/[motorBrand]/${"koerpergroesse"}/[bikerHeight]/preisbereich/[priceRange]/verfugbar/[availability]/${"sortieren"}/${"preis"}/[sortOrder]`
}

export const filterUrl = (params, key, value, t, pageName?, collectionName?, customUrlBase?) => {
	const filteredURL = process.env.NEXT_PUBLIC_STOREFRONT === "mybikeplan" ? filterMBP(params, key, value, t, pageName, collectionName, customUrlBase) : filterMHP(params, key, value, t, pageName, collectionName, customUrlBase)
	return filteredURL
}

const filterMBP = (params, key, value, t, pageName?, collectionName?, customUrlBase?) => {
	switch (pageName) {
		case CollectionName.AllBikes:
			return `/${t("alle-modelle-e-bike-schweiz")}/${t("kategorie")}/${key === urlParams.category ? value : params.category || defaultValue}/${t("rahmenvarianten")}/${key === urlParams.frameVariants ? value : params.frameVariants || defaultValue}/${t("batterie-kapazitat")}/${key === urlParams.batterieSize ? value : params.batterieSize || defaultValue}/${t("max-gewicht")}/${key === urlParams.maxGewicht ? value : params.maxGewicht || defaultValue}/${t("zubehor")}/${key === urlParams.attachments ? value : params.attachments || defaultValue}/${t("geschwindigkeit")}/${key === urlParams.speed ? value : params.speed || defaultValue}/${t("marke")}/${key === urlParams.brand ? value : params.brand || defaultValue}/${t("motor-marke")}/${key === urlParams.motorBrand ? value : params.motorBrand || defaultValue}/${t("farbe")}/${key === urlParams.filtersFarbe ? value : params.filtersFarbe || defaultValue}/${t("koerpergroesse")}/${key === urlParams.bikerHeight ? value : params.bikerHeight || defaultValue}/${slugify(t("preisbereich"))}/${key === urlParams.priceRange ? value : params.priceRange || defaultValue}/${slugify(t("verfugbar"))}/${key === urlParams.availability ? value : params.availability || defaultValue}/${t("sortieren")}/${t("preis")}/${key === urlParams.sortOrder ? value : params.sortOrder || "relevanz"}/${key === urlParams.page ? value : params.page || defaultValue}`
		case CollectionName.Accessories:
			return `/${t("zubehor")}/${t("type")}/${key === urlParams.type ? value : params.type || defaultValue}/${t("marke")}/${key === urlParams.accessoryBrand ? value : params.accessoryBrand || defaultValue}/${slugify(t("preisbereich"))}/${key === urlParams.priceRange ? value : params.priceRange || defaultValue}/${t("sortieren")}/${key === urlParams.sortOrder ? value : params.sortOrder || t("aufsteigend")}`

		case CollectionName.Categories: return `/${t("kategorien")}/${t(collectionName)}?${urlParams.category}=${key === urlParams.category ? value : params.category || defaultValue}&${urlParams.frameVariants}=${key === urlParams.frameVariants ? value : params.frameVariants || defaultValue}&${urlParams.batterieSize}=${key === urlParams.batterieSize ? value : params.batterieSize || defaultValue}&${urlParams.maxGewicht}=${key === urlParams.maxGewicht ? value : params.maxGewicht || defaultValue}&${urlParams.attachments}=${key === urlParams.attachments ? value : params.attachments || defaultValue}&${urlParams.speed}=${key === urlParams.speed ? value : params.speed || defaultValue}&${urlParams.brand}=${key === urlParams.brand ? value : params.brand || defaultValue}&${urlParams.motorBrand}=${key === urlParams.motorBrand ? value : params.motorBrand || defaultValue}&${urlParams.filtersFarbe}=${key === urlParams.filtersFarbe ? value : params.filtersFarbe || defaultValue}&${urlParams.bikerHeight}=${key === urlParams.bikerHeight ? value : params.bikerHeight || defaultValue}&${urlParams.priceRange}=${key === urlParams.priceRange ? value : params.priceRange || defaultValue}&${urlParams.availability}=${key === urlParams.availability ? value : params.availability || defaultValue}&${urlParams.sortOrder}=${key === urlParams.sortOrder ? value : params.sortOrder || "relevanz"}/${key === urlParams.page ? value : params.page || defaultValue}`
		case CollectionName.Brands: return `/${t("marken")}/${t(collectionName)}?${urlParams.category}=${key === urlParams.category ? value : params.category || defaultValue}&${urlParams.frameVariants}=${key === urlParams.frameVariants ? value : params.frameVariants || defaultValue}&${urlParams.batterieSize}=${key === urlParams.batterieSize ? value : params.batterieSize || defaultValue}&${urlParams.maxGewicht}=${key === urlParams.maxGewicht ? value : params.maxGewicht || defaultValue}&${urlParams.attachments}=${key === urlParams.attachments ? value : params.attachments || defaultValue}&${urlParams.speed}=${key === urlParams.speed ? value : params.speed || defaultValue}&${urlParams.brand}=${key === urlParams.brand ? value : params.brand || defaultValue}&${urlParams.motorBrand}=${key === urlParams.motorBrand ? value : params.motorBrand || defaultValue}&${urlParams.filtersFarbe}=${key === urlParams.filtersFarbe ? value : params.filtersFarbe || defaultValue}&${urlParams.bikerHeight}=${key === urlParams.bikerHeight ? value : params.bikerHeight || defaultValue}&${urlParams.priceRange}=${key === urlParams.priceRange ? value : params.priceRange || defaultValue}&${urlParams.availability}=${key === urlParams.availability ? value : params.availability || defaultValue}&${urlParams.sortOrder}=${key === urlParams.sortOrder ? value : params.sortOrder || "relevanz"}/${key === urlParams.page ? value : params.page || defaultValue}`

		case CollectionName.Custom: return `/${t(customUrlBase)}?${urlParams.category}=${key === urlParams.category ? value : params.category || defaultValue}&${urlParams.frameVariants}=${key === urlParams.frameVariants ? value : params.frameVariants || defaultValue}&${urlParams.batterieSize}=${key === urlParams.batterieSize ? value : params.batterieSize || defaultValue}&${urlParams.maxGewicht}=${key === urlParams.maxGewicht ? value : params.maxGewicht || defaultValue}&${urlParams.attachments}=${key === urlParams.attachments ? value : params.attachments || defaultValue}&${urlParams.speed}=${key === urlParams.speed ? value : params.speed || defaultValue}&${urlParams.brand}=${key === urlParams.brand ? value : params.brand || defaultValue}&${urlParams.motorBrand}=${key === urlParams.motorBrand ? value : params.motorBrand || defaultValue}&${urlParams.filtersFarbe}=${key === urlParams.filtersFarbe ? value : params.filtersFarbe || defaultValue}&${urlParams.bikerHeight}=${key === urlParams.bikerHeight ? value : params.bikerHeight || defaultValue}&${urlParams.priceRange}=${key === urlParams.priceRange ? value : params.priceRange || defaultValue}&${urlParams.availability}=${key === urlParams.availability ? value : params.availability || defaultValue}&${urlParams.sortOrder}=${key === urlParams.sortOrder ? value : params.sortOrder || "relevanz"}/${key === urlParams.page ? value : params.page || defaultValue}`

		default:
			// Delete here
			return `/${t("alle-modelle-e-bike-schweiz")}/${t("kategorie")}/${key === urlParams.category ? value : params.category || defaultValue}/${t("rahmenvarianten")}/${key === urlParams.frameVariants ? value : params.frameVariants || defaultValue}/${t("batterie-kapazitat")}/${key === urlParams.batterieSize ? value : params.batterieSize || defaultValue}/${t("max-gewicht")}/${key === urlParams.maxGewicht ? value : params.maxGewicht || defaultValue}/${t("zubehor")}/${key === urlParams.attachments ? value : params.attachments || defaultValue}/${t("geschwindigkeit")}/${key === urlParams.speed ? value : params.speed || defaultValue}/${t("marke")}/${key === urlParams.brand ? value : params.brand || defaultValue}/${t("motor-marke")}/${key === urlParams.motorBrand ? value : params.motorBrand || defaultValue}/${t("farbe")}/${key === urlParams.filtersFarbe ? value : params.filtersFarbe || defaultValue}/${t("koerpergroesse")}/${key === urlParams.bikerHeight ? value : params.bikerHeight || defaultValue}/${slugify(t("preisbereich"))}/${key === urlParams.priceRange ? value : params.priceRange || defaultValue}/${slugify(t("verfugbar"))}/${key === urlParams.availability ? value : params.availability || defaultValue}/${t("sortieren")}/${t("preis")}/${key === urlParams.sortOrder ? value : params.sortOrder || "relevanz"}/${key === urlParams.page ? value : params.page || defaultValue}`
	}
}

const filterMHP = (params, key, value, t, pageName?, collectionName?, customUrlBase?) => {
	switch (pageName) {
		case CollectionName.AllBikes:
			return `/${t("alle-produkte-schweiz")}/${t("kategorie")}/${key === urlParams.category ? value : params.category || defaultValue}/${t("marke")}/${key === urlParams.brand ? value : params.brand || defaultValue}/${t("farbe")}/${key === urlParams.filtersFarbe ? value : params.filtersFarbe || defaultValue}/${slugify(t("preisbereich"))}/${key === urlParams.priceRange ? value : params.priceRange || defaultValue}/${slugify(t("verfugbar"))}/${key === urlParams.availability ? value : params.availability || defaultValue}/${slugify(t("suchen"))}/${key === urlParams.searchValue ? value : params.searchValue || defaultValue}/${t("sortieren")}/${t("preis")}/${key === urlParams.sortOrder ? value : params.sortOrder || "relevanz"}/${key === urlParams.page ? value : params.page || defaultValue}`
			// Case CollectionName.Accessories:
			// 	return `/${t("zubehor")}/${t("type")}/${key === urlParams.type ? value : params.type || defaultValue}/${t("marke")}/${key === urlParams.accessoryBrand ? value : params.accessoryBrand || defaultValue}/${slugify(t("preisbereich"))}/${key === urlParams.priceRange ? value : params.priceRange || defaultValue}/${t("sortieren")}/${key === urlParams.sortOrder ? value : params.sortOrder || t("aufsteigend")}`

			// case CollectionName.Categories: return `/${t("kategorien")}/${t(collectionName)}?${urlParams.category}=${key === urlParams.category ? value : params.category || defaultValue}&${urlParams.frameVariants}=${key === urlParams.frameVariants ? value : params.frameVariants || defaultValue}&${urlParams.batterieSize}=${key === urlParams.batterieSize ? value : params.batterieSize || defaultValue}&${urlParams.maxGewicht}=${key === urlParams.maxGewicht ? value : params.maxGewicht || defaultValue}&${urlParams.attachments}=${key === urlParams.attachments ? value : params.attachments || defaultValue}&${urlParams.speed}=${key === urlParams.speed ? value : params.speed || defaultValue}&${urlParams.brand}=${key === urlParams.brand ? value : params.brand || defaultValue}&${urlParams.motorBrand}=${key === urlParams.motorBrand ? value : params.motorBrand || defaultValue}&${urlParams.filtersFarbe}=${key === urlParams.filtersFarbe ? value : params.filtersFarbe || defaultValue}&${urlParams.bikerHeight}=${key === urlParams.bikerHeight ? value : params.bikerHeight || defaultValue}&${urlParams.priceRange}=${key === urlParams.priceRange ? value : params.priceRange || defaultValue}&${urlParams.availability}=${key === urlParams.availability ? value : params.availability || defaultValue}&${urlParams.sortOrder}=${key === urlParams.sortOrder ? value : params.sortOrder || t("gunstigste")}`
			// case CollectionName.Brands: return `/${t("marken")}/${t(collectionName)}?${urlParams.category}=${key === urlParams.category ? value : params.category || defaultValue}&${urlParams.frameVariants}=${key === urlParams.frameVariants ? value : params.frameVariants || defaultValue}&${urlParams.batterieSize}=${key === urlParams.batterieSize ? value : params.batterieSize || defaultValue}&${urlParams.maxGewicht}=${key === urlParams.maxGewicht ? value : params.maxGewicht || defaultValue}&${urlParams.attachments}=${key === urlParams.attachments ? value : params.attachments || defaultValue}&${urlParams.speed}=${key === urlParams.speed ? value : params.speed || defaultValue}&${urlParams.brand}=${key === urlParams.brand ? value : params.brand || defaultValue}&${urlParams.motorBrand}=${key === urlParams.motorBrand ? value : params.motorBrand || defaultValue}&${urlParams.filtersFarbe}=${key === urlParams.filtersFarbe ? value : params.filtersFarbe || defaultValue}&${urlParams.bikerHeight}=${key === urlParams.bikerHeight ? value : params.bikerHeight || defaultValue}&${urlParams.priceRange}=${key === urlParams.priceRange ? value : params.priceRange || defaultValue}&${urlParams.availability}=${key === urlParams.availability ? value : params.availability || defaultValue}&${urlParams.sortOrder}=${key === urlParams.sortOrder ? value : params.sortOrder || t("gunstigste")}`

			// case CollectionName.Custom: return `/${t(customUrlBase)}?${urlParams.category}=${key === urlParams.category ? value : params.category || defaultValue}&${urlParams.frameVariants}=${key === urlParams.frameVariants ? value : params.frameVariants || defaultValue}&${urlParams.batterieSize}=${key === urlParams.batterieSize ? value : params.batterieSize || defaultValue}&${urlParams.maxGewicht}=${key === urlParams.maxGewicht ? value : params.maxGewicht || defaultValue}&${urlParams.attachments}=${key === urlParams.attachments ? value : params.attachments || defaultValue}&${urlParams.speed}=${key === urlParams.speed ? value : params.speed || defaultValue}&${urlParams.brand}=${key === urlParams.brand ? value : params.brand || defaultValue}&${urlParams.motorBrand}=${key === urlParams.motorBrand ? value : params.motorBrand || defaultValue}&${urlParams.filtersFarbe}=${key === urlParams.filtersFarbe ? value : params.filtersFarbe || defaultValue}&${urlParams.bikerHeight}=${key === urlParams.bikerHeight ? value : params.bikerHeight || defaultValue}&${urlParams.priceRange}=${key === urlParams.priceRange ? value : params.priceRange || defaultValue}&${urlParams.availability}=${key === urlParams.availability ? value : params.availability || defaultValue}&${urlParams.sortOrder}=${key === urlParams.sortOrder ? value : params.sortOrder || t("gunstigste")}`

		default:
			// Delete here
			return `/${t("alle-produkte-schweiz")}/${t("kategorie")}/${key === urlParams.category ? value : params.category || defaultValue}/${t("marke")}/${key === urlParams.brand ? value : params.brand || defaultValue}/${t("farbe")}/${key === urlParams.filtersFarbe ? value : params.filtersFarbe || defaultValue}/${slugify(t("preisbereich"))}/${key === urlParams.priceRange ? value : params.priceRange || defaultValue}/${slugify(t("verfugbar"))}/${key === urlParams.availability ? value : params.availability || defaultValue}/${slugify(t("suchen"))}/${key === urlParams.searchValue ? value : params.searchValue || defaultValue}/${t("sortieren")}/${t("preis")}/${key === urlParams.sortOrder ? value : params.sortOrder || "relevanz"}/${key === urlParams.page ? value : params.page || defaultValue}`
	}
}

export const filterRoute = () => {
	// Delete here

	return defaultUrl[process.env.NEXT_PUBLIC_STOREFRONT]
}
