import React, {useContext} from "react"
import Image from "next/image"
import PropTypes from "prop-types"
import Typography from "./Typography/Typography"
import BookAppointmentButton from "./ModalButtons/BookAppointmentButton"
import AskAQuestionModalButton from "./ModalButtons/AskAQuestionModalButton"
import {ModalContext} from "../../context/ModalContext"
import AskAQuestion from "../bikeDetails/AskAQuestion"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import LayoutContext from "../../context/LayoutContext"
import AnalyticsContext from "../../context/AnalyticsContext"

type Props = {
  t?: any,
  white?: boolean
}

const BikeSuggest: React.FC<Props> = ({t, white}) => {
	const {setModalContent, openModal, closeModal} = useContext(ModalContext)
	const {isMobile} = useContext(LayoutContext)
	const {reactGA} = useContext(AnalyticsContext)

	const handleClick = () => {
		reactGA?.event({
			category: gaCategories.feedback,
			action: gaEvents.suggestProduct,
			label: gaEvents.suggestProduct,
			nonInteraction: false
		})
		setModalContent(<AskAQuestion setAskAQuestion={closeModal}/>)
		openModal()
	}

	return (<>
		{isMobile ? <div className="mb-5">
			<div className={"position-relative vh-25"}>
				<Image src="/assets/images/blackFriday/mhp-black-friday-image-3.png" alt="bike" layout="fill" objectFit="contain"
				/>
			</div>
			<div className={"px-3 position-relative pt-1 pt-md-3"}>
				<Typography variant="bodyLgBlack" semanticTag="h2"
					style={{fontSize: 28}}>{t("Brauchst Du Hilfe bei der Suche nach deinem perfekten Sofa?")}</Typography>
				<div className="mt-4 mb-3">
					<BookAppointmentButton hoverColor="#212529" textColor="#fff" color="#fff" bgColor="#212529"
						className="col-12" outline
						fontSizeSmall plp label={t("Kostenlose Beratung buchen")}/>
				</div>
				<AskAQuestionModalButton hasIcon={false} button label={t("Stelle eine Frage")}/>
			</div>
		</div> :
			<div style={{marginBottom: "-25px"}}>
				<div className={" vh-35 d-sm-block d-lg-none"}>
					<Image src="/assets/images/bike-1.jpg"
						alt="bike" layout="fill" objectFit="contain"
					/>
				</div>
				<div className={"pt-1 pt-md-3 d-flex col-12"}>
					<div style={{zIndex: -1}} className={`container ${white ? "" : "bg-light"} p-3 pt-md-4  col-7`}>
						<div className={"row g-0 align-items-center justify-content-center"}>
							<div className={"col-sm-12 col-md-8 col-xl-8"}>
								<div className={"row"}>
									<div className={"col-12 col-lg-10"}>
										<div className={"d-none d-md-block me-4"}>
											<Typography variant="heading2" semanticTag="h2"
												className={"text-center text-md-start"}>{t("Brauchst Du Hilfe bei der Suche nach deinem perfekten Sofa?")}</Typography>
											<Typography variant="bodyLg"
												semanticTag="p">{t("Gerne unterstützen wir dich bei der Entscheidung mit einer kostenlosen Beratung oder wir notieren deine Vorschläge für neue Sofas.")}</Typography>
										</div>
										<div className={"d-block d-md-none me-4"}>
											<Typography variant="heading2" semanticTag="h2"
												className={"text-center text-md-start"}>{t("Hast dein perfektes E-Bike nicht gefunden? Sag uns, welches E-Bike du gerne hättest")}</Typography>
										</div>
									</div>
								</div>
								<div className={"row mt-4 mb-4 mb-md-5 d-flex"}>
									<div className={"col-12 col-md-auto mb-3"}>
										<BookAppointmentButton plp label={t("Kostenlose Beratung buchen")} hoverColor="#212529"
											textColor="#fff" color="#fff" bgColor="#212529"/>
									</div>
									<div className={"col-12 col-md-auto"}>
										<AskAQuestionModalButton button label={t("Stelle eine Frage")}/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="d-none d-sm-none d-lg-block col-5 position-relative" style={{zIndex: -1}}>
						<Image src="/assets/images/blackFriday/mhp-black-friday-image-2.png"
							alt="bike" layout="fill" objectFit="cover"
							objectPosition="center"
						/></div>
				</div>
			</div>
		}
	</>
	)
}

BikeSuggest.propTypes = {
	t: PropTypes.any.isRequired,
	white: PropTypes.bool

}

export default BikeSuggest
