/* eslint-disable capitalized-comments */
/* eslint-disable camelcase */
import React, {useContext, useEffect, useMemo, useRef, useState} from "react"
import {useTranslation} from "next-i18next"
import {
	findBikeAttributeByName,
	findBikeMetaFieldByName,
	getQueryParams,
	isCategory,
	isOccasionBike
} from "../../utility/Helper"
import getMainCategory from "../filter/GetMainCategory"
import {useRouter} from "next/router"
import CartContext, {CartItem} from "../../context/CartContext"
import CheckoutContext from "../../context/CheckoutContext"
import LayoutContext from "../../context/LayoutContext"
import SimilarBikes from "./SimilarBikes"
import PropTypes from "prop-types"
import SameCategoryBikes from "../reusable/SameCategoryBikes"
import AskAQuestion from "./AskAQuestion"
import FAQ from "../home/faq/FAQ"
import Sizes from "./sizes/Sizes"
import getAccordionContent from "./AccordionContent"
import GoBack from "./GoBack"
import BikePrice from "./BikePrice"
import Colors from "./colors/Colors"
import Typography from "../reusable/Typography/Typography"
import DeliveryAndReturnsSidebar from "./DeliveryAndReturnsSidebar"
import BikeOrdersCount from "./BikeOrdersCount"
import {generateUUID} from "../../utility/UUID"
import MobileShare from "../reusable/components/MobileShare"
import AnalyticsContext from "../../context/AnalyticsContext"
import Image from "next/image"
import PDPTooltip from "./PDPTooltip"
import BookAppointmentButton from "../reusable/ModalButtons/BookAppointmentButton"
import AnimationComponent from "../reusable/components/AnimationComponent/AnimationComponent"
import AboutThisBike from "./AboutThisBike"
import {categorySlugs} from "../filter/config/Categories"
import {CheckoutFixedBtn} from "./components/CheckoutFixedBtn"
import {CheckoutBtn, QAndA} from "./components"
import useOnScreen from "../../hooks/OnScreen"
import CustomSlider from "../imageSlides/CustomSlider"
import CustomerQuestionsSection from "./CustomerQuestionsSection"
import {useVariant} from "../../hooks/googleOptimize/useVariant"
import CrossSellProducts from "./CrossSellProducts"
import DataContext from "../../context/DataContext"
import {ModalContext} from "../../context/ModalContext"
import CartCompatibilityModalContent from "../reusable/ModalsContent/CartCompatibilityModalContent"
import ShortDescriptionPDP from "./ShortDescriptionPDP"
import {ecommerceEvents, gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import BookConsultation from "./BookConsultation"
import NumberOfView from "./NumberOfView"
import slugify from "slugify"
import DurationAndDownpayment from "./components/DurationAndDownpayment"
import ComparisonSection from "./ComparisonSection"
import {placeholders} from "../story/Story"
import Stories from "../story/Stories"
import ServiceCenters from "../home/serviceCenters/ServiceCenters"
import ProductInfo from "./ProductInfo"
import OurReview from "./components/OurReview"
import {colors} from "../../colors/Colors"
import ComparisonPLPFixedComponent from "../bikesCollection/ComparisonPLPFixedComponent"
import UpsellModalContent from "../reusable/ModalsContent/UpsellModalContent"
import {SaleTypes} from "./plan/OnSaleDurationLabels"
import {getDiscountData} from "../../config/discountsConfig"
import NumberOfVisitors from "../reusable/NumberOfVisitors"
import NumberButton from "../button/NumberButton"
import RelatedProducts from "./RelatedProducts"
import LoadingOverlay from "../routeTransition/LoadingOverlay"
import InfoBox from "./InfoBox"
import {customPlanLengthMap} from "./plan/customPlanLengthMap"
import FindPerfectFurniture from "../home/FindPerfectFurniture/FindPerfectFurniture"
import usePrice from "../../hooks/usePrice"
import OrganizationContext from "../../context/OrganizationContext"
import RecentlyViewedProducts from "../home/RecentlyViewedProducts/RecentlyViewedProducts"
import SoldOut from "./SoldOut"
import MiniPLP from "../home/miniPLP/MiniPLP"

export const StockStatus = {
	inStock: "instock",
	outOfStock: "outofstock",
	onBackOrder: "onbackorder"
}

type Props = {
  bike: any
  reviews: any
  isSecondHand?: boolean
  questions: any[]
  numberOfPeopleView: number
}

const OZ_CONFIG = {
	settings: {
		apiKey: process.env.NEXT_PUBLIC_ONLINESIZER_KEY,
		language: "de",
		primary: `${colors.primary}`,
		cta: "#23A455",
		text: "black",
		background: "rgba(0, 0, 0, 0.5)",
		buttonTextColor: "#fff",
		roundness: "2px",
		units: "metric",
		frameSizeDisplay: "numerical",
		defaultGender: "male"
	}
}

// eslint-disable-next-line complexity
const BikeDetails: React.FC<Props> = ({questions, bike, reviews, numberOfPeopleView = 1}) => {
	const isSecondHand = isOccasionBike(bike)
	const variant = useVariant(process.env.NEXT_PUBLIC_ENVIRONMENT === "production" ? "fKnJ1NnfQfituw1xLmvqfw" : "qeeQ124uSdSXkvWCiv0FLA")
	const brand = bike?.attributes.find(e => e.name === "Marke")?.options[0]
	const {t} = useTranslation(["common", "secondHandBikes"])

	// GetSaleType category value
	const {isOnSale, discountKey} = getDiscountData(bike)
	const saleType = discountKey as SaleTypes

	// Contexts
	const {addItem, items, updateItem} = useContext(CartContext)
	const {data, setData} = useContext(CheckoutContext)
	const checkoutData = data

	// states
	const [selectedSize, setSelectedSize] = useState<string>(null)
	const [selectedVariation, setSelectedVariation] = useState<any>(null)
	const [askAQuestion, setAskAQuestion] = useState(false)
	const [variations, setVariations] = useState([])
	const [isLoadingVariations, setIsLoadingVariations] = useState(false)
	const [soldOut, setSoldOut] = useState(bike.status === "draft")
	const [isOpen, setIsOpen] = useState(false)
	const [scriptsLoaded] = useState([])
	const [addingToCart, setAddingToCart] = useState(false)
	const [isShaking, setIsShaking] = useState(false)
	const {finalPriceWithDownPayment, finalPriceWithoutDownPayment, onSale, discount} = usePrice(bike)
	const {organizationSlug} = useContext(OrganizationContext)
	const [numberOfItemsToAdd, setNumberOfItemsToAdd] = useState(1)
	const mainCategory = getMainCategory(bike.categories)
	const isAccessory = mainCategory?.name.toLowerCase() === categorySlugs.accessories
	const secondHandPrice = bike.displayPrice || findBikeMetaFieldByName(bike, "secondhand_price")
	const mileage = Number(findBikeMetaFieldByName(bike, "mileage"))
	const frameNumber = findBikeMetaFieldByName(bike, "frame_number")

	const {pageProps} = useContext(DataContext)
	const {contentfulPageData, deals} = pageProps

	const bikeOrdersCount = useMemo(() => bike?.ordersLastWeek, [bike])
	const [additionalInfoDrawer, setAdditionalInfoDrawer] = useState<boolean>(false)
	const {reactGA} = useContext(AnalyticsContext)

	const router = useRouter()
	const {tab} = getQueryParams(router.asPath)

	const {isMobile} = useContext(LayoutContext)
	const scriptsRef = useRef([])
	scriptsRef.current = scriptsLoaded

	// Price variables
	const regularDiscountBase = findBikeMetaFieldByName(bike, "regular_price") && findBikeMetaFieldByName(bike, "regular_price")
	const formattedPrice = new Intl.NumberFormat("ch-CH", {
		style: "currency",
		currency: "CHF",
		minimumFractionDigits: 0
	}).format(finalPriceWithDownPayment)
	const formattedPriceForSecondHand = new Intl.NumberFormat("ch-CH", {
		style: "currency",
		currency: "CHF",
		minimumFractionDigits: 0
	}).format(secondHandPrice)

	const accordionContentOrigin = useMemo(() => getAccordionContent(bike, isAccessory, t, contentfulPageData), [bike, isAccessory, isMobile])
	const accordionContent = [...accordionContentOrigin, {
		title: `${t("customers_questions")} ${questions?.length ? `(${questions?.length})` : ""} `,
		slug: t("customers_questions"),
		content: <CustomerQuestionsSection selectedVariation={selectedVariation} bike={bike} questions={questions}/>,
		titleContent: <Typography semanticTag="h3"
			variant={"heading3"}>{`${t("customers_questions")} ${questions?.length ? `(${questions?.length})` : ""}`}</Typography>
	}]

	const stockInfo = bike.stock_status === StockStatus.inStock ? "in-stock" : ""

	const checkoutBtnRef = useRef<HTMLDivElement>()
	const topOfPageRef = useRef<HTMLDivElement>()

	const checkoutBtnOnScreen = useOnScreen(checkoutBtnRef)
	const [showCheckoutBtnOnScreen, setShowCheckoutBtnOnScreen] = useState(false)

	const {setModalContent, openModal, closeModal} = useContext(ModalContext)

	const isCartCompatibility = items.length === 0 ? true : isSecondHand === (isSecondHand ? items?.filter(item => !isOccasionBike(item.product)).length < 1 : items?.filter(item => !isOccasionBike(item.product)).length < 1)
	const shortDescription = findBikeMetaFieldByName(bike, "short_description")
	const is2023 = isCategory(bike, "2023")
	const isBikeOfTheMonth = isCategory(bike, "E-Bike of the Month")

	const [active, setActive] = useState<string>("")

	const refToBikeDescription = useRef(null)
	const executeScroll = () => {
		setActive(slugify(t("customers_questions").toLowerCase()))
		refToBikeDescription.current.scrollIntoView()
	}

	const planLengthMap = customPlanLengthMap[process.env.NEXT_PUBLIC_DISCOUNTS_INSTANCE]
	const planLength = checkoutData?.planLength ? planLengthMap ? planLengthMap[checkoutData?.planLength] : checkoutData?.planLength : 48

	// show sticky button only scroll past actual CTA
	useEffect(() => {
		setShowCheckoutBtnOnScreen((topOfPageRef.current?.getBoundingClientRect().top < -800) && !checkoutBtnOnScreen)
	}, [topOfPageRef.current?.getBoundingClientRect().top, checkoutBtnOnScreen])

	const handleCheckoutBtnClick = async () => {
		if (isCartCompatibility) {
			setAddingToCart(true)
			const resultBike = isSecondHand ? {...bike, price: parseInt(secondHandPrice, 10), frameNumber} : {
				...bike,
				finalPriceWithoutDownPayment
			}
			for (let i = 0; i < numberOfItemsToAdd; i++) {
				addItem({
					product: {
						...resultBike,
						sku: selectedVariation?.sku,
						bikeQuantity: 1,
						totalQuantity: 1,
						customInstanceDiscount: discount,
						finalPriceWithoutDownPayment,
						totalPrice: bike.uvpPrice,
						expected_delivery_date__website_: findBikeMetaFieldByName(selectedVariation, "delivery_estimation")
					},
					isOnSale: onSale,
					selectedSize,
					selectedVariationId: parseInt(selectedVariation?.id, 10),
					uniqueId: generateUUID(),
					initialPrice: bike.uvpPrice
				} as CartItem)
			}

			setData(prevData => ({
				...prevData,
				accessoriesFilled: isAccessory
			}))

			reactGA?.event({
				category: gaCategories.pdp,
				action: gaEvents.addToCart,
				label: `${gaEvents.addToCart} ${bike.name}`,
				nonInteraction: true
			})

			reactGA?.gtag(ecommerceEvents.event, ecommerceEvents.addToCart, {
				currency: ecommerceEvents.CHF,
				value: Number(bike?.uvpPrice),
				items: [
					{
						item_id: selectedVariation?.sku,
						item_name: bike?.name
					}
				]
			})

			// Upsell modal  show or no
			if (!isAccessory && bike.crossSellProducts && bike.crossSellProducts.filter(product => product.stock_status === StockStatus.inStock || product.stock_status === StockStatus.onBackOrder).length) {
				setModalContent(<UpsellModalContent deals={deals} bike={bike} sellProducts={bike.crossSellProducts}
					setAddingToCart={setAddingToCart}/>)
				openModal()
			} else {
				router.push(t("/checkout")).then()
			}
		} else {
			const resultBike = isSecondHand ? {...bike, price: secondHandPrice, frameNumber} : {...bike}
			const compatibilityBike = {
				product: {
					...resultBike,
					sku: selectedVariation?.sku,
					expected_delivery_date__website_: findBikeMetaFieldByName(selectedVariation, "delivery_estimation")
				},
				isOnSale: onSale,
				initialPrice: bike?.uvpPrice,
				selectedSize,
				selectedVariationId: parseInt(selectedVariation?.id, 10),
				uniqueId: generateUUID()
			} as CartItem
			setModalContent(<CartCompatibilityModalContent closeModal={closeModal} currentBike={compatibilityBike}/>)
			openModal()
		}
	}

	useEffect(() => {
		setIsLoading(false)
		const storedRecentlyViewed = JSON.parse(localStorage.getItem("recentlyViewedProducts")) || []
		const bikeExistsIndex = storedRecentlyViewed.findIndex(el => el?.id === bike.id)

		let updatedRecentlyViewed = []
		if (bikeExistsIndex === -1) {
			updatedRecentlyViewed = [bike, ...storedRecentlyViewed]
		} else {
			updatedRecentlyViewed = storedRecentlyViewed
		}

		if (updatedRecentlyViewed.length > 10) {
			updatedRecentlyViewed = updatedRecentlyViewed.slice(0, 10)
		}

		localStorage.setItem("recentlyViewedProducts", JSON.stringify(updatedRecentlyViewed))
		window.dispatchEvent(new Event("RecentlyViewedUpdated"))
	}, [bike])

	const startShake = () => {
		setIsShaking(true)
		setTimeout(() => setIsShaking(false), 1000)
	}

	useEffect(() => {
		if (JSON.parse(localStorage.getItem("pdpViewed"))) {
			const pageView = JSON.parse(localStorage.getItem("pdpViewed"))
			pageView.times++

			localStorage.setItem("pdpViewed", JSON.stringify(pageView))
		} else {
			localStorage.setItem("pdpViewed", JSON.stringify({viewed: true, times: 0}))
		}
	}, [])

	useEffect(() => {
		if (selectedVariation?.sku) {
			reactGA?.gtag(ecommerceEvents.event, ecommerceEvents.viewItem, {
				currency: ecommerceEvents.CHF,
				value: Number(bike?.uvpPrice),
				items: [
					{
						item_id: selectedVariation?.sku,
						item_name: bike?.name
					}
				]
			})
		} else {
			reactGA?.gtag(ecommerceEvents.event, ecommerceEvents.viewItem, {
				currency: ecommerceEvents.CHF,
				value: Number(bike?.uvpPrice),
				items: [
					{
						item_id: bike?.sku,
						item_name: bike?.name
					}
				]
			})
		}
	}, [bike?.slug])
	const [isLoading, setIsLoading] = useState(false)
	const onClick = () => {
		setIsLoading(true)
	}

	useEffect(() => {
		setTimeout(() => window.scroll({top: 0}), 10)
	}, [isLoading])
	useEffect(() => {
		let variationsSoldOut = true

		bike.staticVariations.forEach(variation => {
			if (variation.stock_status === StockStatus.inStock || variation.stock_status === StockStatus.onBackOrder) {
				variationsSoldOut = false
			}
		})
		setSoldOut(bike.status === "draft" || variationsSoldOut)
		// Sort variations according to size
		const _response = [...bike.staticVariations]
		_response.sort((a, b) => (parseInt(findBikeAttributeByName(a, "Grösse", false, true).replace(/\D/g, ""), 10) > parseInt(findBikeAttributeByName(b, "Grösse", false, true).replace(/\D/g, ""), 10)) ? 1 : ((parseInt(findBikeAttributeByName(b, "Grösse", false, true).replace(/\D/g, ""), 10) > parseInt(findBikeAttributeByName(a, "Grösse", false, true).replace(/\D/g, ""), 10)) ? -1 : 0))

		setVariations(_response)
	}, [bike.staticVariations])

	useEffect(() => {
		if (variations.length === 1) {
			const sizeAttribute = findBikeAttributeByName(variations[0], "Grösse", false, true)
			const [size] = sizeAttribute.split(": ")
			setSelectedVariation(variations[0])
			setSelectedSize(size)
		}
	}, [variations])

	useEffect(() => {
		// eslint-disable-next-line max-params
		((win, doc, script, key, config, src) => {
			// @ts-ignore
			win.___OnlineSizing = key
			// @ts-ignore
			win.___OnlineSizingConfig = config
			const js = doc.createElement("script")
			js.id = key
			js.src = src
			js.async = true

			const scripts = doc.getElementsByTagName(script)
			const lastScript = scripts[scripts.length - 1]
			lastScript.parentNode.insertBefore(js, lastScript)
		})(window, document, "script", "oz", OZ_CONFIG, "https://widgets.onlinesizing.bike/loader.js")
	}, [])
	const cypressClassName = `cypress-test-consistency-price-${bike?.uvpPrice}`
	return (
		<div className={"position-relative mt-4 mt-lg-0"}>
			<div className="d-none" data-cypress-total-price={cypressClassName}>
				{finalPriceWithoutDownPayment}
			</div>
			{isLoading ? <LoadingOverlay isVisible={isLoading} /> : ""}
			<div className="d-block d-md-none">
				<AnimationComponent inProp={showCheckoutBtnOnScreen} type="slideRight">
					<CheckoutFixedBtn setNumberOfItemsToAdd={value => setNumberOfItemsToAdd(value)} iconSize={15}
						disabled={(isAccessory ? false : !selectedSize) && !(variations.length === 1)}
						isLoading={addingToCart} handleClick={handleCheckoutBtnClick} variations={variations} selectedSize={selectedSize}
						selectedVariation={selectedVariation} setSelectedVariation={setSelectedVariation}
						setSelectedSize={setSelectedSize}/>
				</AnimationComponent>
			</div>
			{bike.sku ? <div className="smartfit-onlinesizing" id="onlinesizer-target"/> : null}
			<div ref={topOfPageRef} className={"container pt-2"}>
				<div className="d-none d-md-block">
					<GoBack onClickFallback={onClick} showBikeInfo={false} isBikeOfTheMonth={isBikeOfTheMonth} is2023={is2023} name={bike.name}
						brand={brand} stockInfo={t(stockInfo)} isSecondHand={isSecondHand} withText/>
				</div>
				<div className={"row justify-content-between"}>
					<div
						className="d-flex col-12 justify-content-between d-block d-md-none mt-3 container align-items-center position-absolute"
						style={{top: 0, left: 0, zIndex: 10}}>
						<div className="col-auto">
							<GoBack onClickFallback={onClick} showBikeInfo={false} isBikeOfTheMonth={isBikeOfTheMonth} is2023={is2023} name={bike.name}
								brand={brand} stockInfo={t(stockInfo)} isSecondHand={isSecondHand}/>
						</div>
						<div className="col-auto">
							<MobileShare title={bike.name}
								className=""/>
						</div>
					</div>
					<NumberOfVisitors label={"In den letzten 7 Tagen x-mal angeschaut"} isMobile={isMobile} amount={bike.views} />
					<div className="d-none d-md-block col-md-6 col-lg-8 pb-md-5 sticky-wrapper-pdp">
						<CustomSlider showDescription slidesList={bike.images} bike={bike} withGalery hasComparisonBtn arrows hasWhatsappSlide isAccessory={isAccessory}/>
					</div>
					<div className="col-12 main-slider-pdp d-md-none px-0 pb-md-5 w-100">
						<CustomSlider showDescription slidesList={bike.images} bike={bike} hasComparisonBtn dots hasWhatsappSlide isAccessory={isAccessory}/>
						{!isAccessory && <div className="d-md-none">
							<ComparisonPLPFixedComponent sticky={false}/>
						</div>}
					</div>
					<div className={"col-12 col-md-5 col-lg-4 mt-md-3"}>
						<div className="d-md-none">
							<div className="row justify-content-start mt-4">
								<BikeOrdersCount count={bikeOrdersCount} t={t}/>
							</div>
							<div className="mt-3 pt-2">
								<Typography variant="bodyLgBlack" semanticTag="h2"
									style={{fontSize: 24}}>{t(bike.name)}</Typography>
								{numberOfPeopleView > 0 && <NumberOfView numberOfPeopleView={numberOfPeopleView}/>}
							</div>
							<OurReview bike={bike}/>
							{typeof shortDescription !== "undefined" &&
              <ShortDescriptionPDP shortDescription={shortDescription} isMobile={isMobile}/>}
							<div className="d-flex justify-content-between align-items-center mb-1">
								<BikePrice
									bikeId={bike.id}
									regularDiscountBase={regularDiscountBase}
									isSecondHand={isSecondHand}
									price={finalPriceWithoutDownPayment}
									secondHandPrice={Number(secondHandPrice)}
									isOnSale={isOnSale}
									initialPrice={Number(bike.uvpPrice)}
								/>
								<Image onClick={() => setIsOpen(!isOpen)}
									src={`${isOpen ? "/assets/icons/info-active.svg" : "/assets/icons/info-transparent.svg"}`}
									height={18}
									width={18} className="pointer"
								/>
							</div>
							<div id="size" className="position-relative">
								{!isSecondHand && (
									<Typography variant="bodySm" semanticTag="div" style={{color: "#6C7074"}}>
										{`${t("Gesamtpreis")} - ${Number(finalPriceWithoutDownPayment)?.toFixed(2)}`}
									</Typography>)}

								{isSecondHand && (<>
									<Typography variant="bodySm" semanticTag="span" className={"text-secondary"}
										style={{color: "#6C7074"}}>
										{`${t("Gesamtpreis")} - `}
									</Typography>
									<Typography variant="bodySm" semanticTag="span"
										className={"text-decoration-line-through"} style={{color: "#6C7074"}}>
										{`${(bike.uvpPrice).toFixed(2)}`}
									</Typography>
									<Typography variant="bodySm" semanticTag="span" className={"ms-2"}
										style={{color: "#6C7074"}}>
										{`${formattedPriceForSecondHand}`}
									</Typography>
								</>
								)}
								<PDPTooltip isOpen={isOpen} setIsOpen={setIsOpen} label="financing"/>
							</div>
						</div>
						<div className="d-none d-md-block">
							<div
								className="row mb-3">
								<BikeOrdersCount count={bikeOrdersCount} t={t}/>
							</div>
							<div className="row">
								{isSecondHand && (
									<Typography variant="bodySm" semanticTag="span"
										className={"col-3 text-uppercase text-end text-bold "}>
										{t("second-hand", {ns: "secondHandBikes"})}
									</Typography>
								)}
							</div>
							<div>
								<Typography variant="heading2Black" semanticTag="h2" style={{fontSize: 28}}>
									{t(bike.name)}
								</Typography>

								<div className="d-flex flex-wrap">
									{isBikeOfTheMonth ?
										<Typography variant="bodyLgBold" semanticTag="span" style={{backgroundColor: "#000"}}
											className="badge text-white text-uppercase py-1 px-3 mt-2 me-2">{t("bike-of-the-month")}
										</Typography> : null
									}
									{is2023 && <Typography variant="bodyLgBold" semanticTag="span" style={{backgroundColor: "#020887"}}
										className="badge text-white text-uppercase py-1 px-3 mt-2">{t("2023-model")}
									</Typography>
									}
								</div>

							</div>
							<OurReview bike={bike}/>
							{typeof shortDescription !== "undefined" && <ShortDescriptionPDP shortDescription={shortDescription}/>}
							<div className="d-flex justify-content-between align-items-center mb-1">
								<BikePrice
									bikeId={bike.id}
									regularDiscountBase={regularDiscountBase}
									isSecondHand={isSecondHand}
									price={finalPriceWithoutDownPayment}
									secondHandPrice={Number(secondHandPrice)}
									initialPrice={Number(bike?.uvpPrice)}
									isOnSale={isOnSale}
								/>
							</div>
							<Typography className="mb-2" variant="bodySm" semanticTag="div" style={{color: "#6C7074"}}>
								{`${t("Gesamtpreis")} - ${Number(finalPriceWithoutDownPayment)?.toFixed(2)}`}
							</Typography>
						</div>
						<div className="d-md-none">
							{isSecondHand && (<>
								<Typography className="mb-2 mt-3 text-uppercase" variant="bodySmBold"
									semanticTag="div">{t("condition", {ns: "secondHandBikes"})}</Typography>
								<Typography className="mb-2 mt-3" variant="bodySm"
									semanticTag="div">{t("perfect", {ns: "secondHandBikes"})}</Typography>
								<Typography className="mb-2 mt-3 text-uppercase" variant="bodySmBold"
									semanticTag="div">{t("mileage", {ns: "secondHandBikes"})}</Typography>
								<Typography className="mb-2 mt-3" variant="bodySm" semanticTag="div">{`${mileage}km`}</Typography>
							</>)}
						</div>
						<div className="mb-2 mt-4 mb-md-4">
							<AboutThisBike bike={bike} mostAskedOnly small/>
						</div>
						{(!isAccessory && !isSecondHand) &&
							<div className="row">
								<Typography variant="bodySm" className="col-12 mb-2 mt-4" semanticTag="div">
									{t(" Farbe")}
								</Typography>
								<div className="col-12">
									<Colors colorData={bike.colorData} />
								</div>
								<RelatedProducts items={(bike.relatedAttributeSizes || bike.relatedCategorySizes)} label={t("Weitere Grössen")}/>
								<RelatedProducts items={bike.relatedCategoryForms} label={t("Weitere Varianten")}/>
								<RelatedProducts items={bike.relatedLegs} label={t("Weitere Beinarten")}/>
							</div>
						}
						{isAccessory ? null : isLoadingVariations ?
							<div className="row mt-4">
								<div className="col-auto">
									<span className="spinner-border spinner-border-sm text-primary" role="status"
										aria-hidden="true"/>
								</div>
							</div> :
							isSecondHand ? null :
								<Sizes shake={isShaking} selectedVariation={selectedVariation} variations={variations}
									scriptsRef={scriptsRef} bike={bike} setSelectedVariation={setSelectedVariation}
									setSelectedSize={setSelectedSize} selectedSize={selectedSize}/>
						}
						<div className="d-md-none">
							{isSecondHand && (<>
								<Typography className="mb-2 mt-3 text-uppercase" variant="bodySmBold"
									semanticTag="div">{t("condition", {ns: "secondHandBikes"})}</Typography>
								<Typography className="mb-2 mt-3" variant="bodySm"
									semanticTag="div">{t("perfect", {ns: "secondHandBikes"})}</Typography>
								<Typography className="mb-2 mt-3 text-uppercase" variant="bodySmBold"
									semanticTag="div">{t("mileage", {ns: "secondHandBikes"})}</Typography>
								<Typography className="mb-2 mt-3" variant="bodySm" semanticTag="div">{`${mileage}km`}</Typography>
							</>)}
						</div>
						{bike.sku ?
							<div className={" col-auto mt-4"}>
								<div id=" onlinesizer-target">
									<button
										id=" my-custom-oz-button"
										className=" oz-trigger btn p-0"
										data-oz-code={bike.sku}
										data-oz-name={bike.name}
										data-oz-image={bike.images[0].src}
										data-oz-price={finalPriceWithoutDownPayment}
										data-oz-fullscreen=" true"
									>
										<img className={" me-2"} src={"/assets/size-calculator-icon.svg"} width={20} height={" auto"}
											alt=" calculator"/>
										<u className={" text-bold"}>{t(" Grösse berechnen")}</u>
									</button>
								</div>
							</div> :
							null}
						<div className="d-md-none mt-4">
							<DurationAndDownpayment
								product={bike}
								isMobile={isMobile}
								isSecondHand={isSecondHand} isOpen={isOpen}
								setIsOpen={setIsOpen}
								disabled={(isAccessory ? false : !selectedSize) && !(variations.length === 1)}
								isLoading={addingToCart}
								handleClick={handleCheckoutBtnClick}
								isAccessory={isAccessory}
								handleSizeClick={startShake}
								priceInputBesidesTitle planLength={planLength} hasPlaceHolder
								customInstanceDiscount={bike?.customInstanceDiscounts?.[organizationSlug]}
							/>
						</div>
						<div className="d-none d-md-block">
							{isSecondHand && (<>
								<Typography className="mb-2 mt-3 text-uppercase" variant="bodySmBold"
									semanticTag="div">{t("condition", {ns: "secondHandBikes"})}</Typography>
								<Typography className="mb-2 mt-3" variant="bodySm"
									semanticTag="div">{t("perfect", {ns: "secondHandBikes"})}</Typography>
								<Typography className="mb-2 mt-3 text-uppercase" variant="bodySmBold"
									semanticTag="div">{t("mileage", {ns: " secondHandBikes"})}</Typography>
								<Typography className="mb-2 mt-3" variant="bodySm" semanticTag="div">{`${mileage}km`}</Typography>
							</>)}
							<div className={"row mt-4"}>
								<div className={"col-12"}>
									<div className="position-relative">
										<PDPTooltip isOpen={isOpen} setIsOpen={setIsOpen} desktop label="financing"/>
									</div>
									<DurationAndDownpayment
										product={bike}
										isMobile={isMobile}
										isSecondHand={isSecondHand} isOpen={isOpen}
										setIsOpen={setIsOpen}
										disabled={(isAccessory ? false : !selectedSize) && !(variations.length === 1)}
										isLoading={addingToCart}
										handleClick={handleCheckoutBtnClick}
										isAccessory={isAccessory}
										handleSizeClick={startShake}
										priceInputBesidesTitle planLength={planLength} hasPlaceHolder
										customInstanceDiscount={bike?.customInstanceDiscounts?.[organizationSlug]}
									/>
								</div>
							</div>
							<div className={"row mt-4 mb-4 align-items-center"}>
								<div className={"col-12 mt-3"}>
									<div className={"d-grid"}>
										{!isCartCompatibility && <Typography variant="bodyLg" semanticTag="p">
											{t("First and secondhand ebikes can’t be in the cart together.")}
										</Typography>}
										{process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && bike.customInstanceDiscount ? null :
											<div className="mt-4">
												<div className={"row align-items-center g-2"}>
													<div className={"col-4"}>
														<NumberButton defaultValue={1} min={1} max={null} onChange={value => setNumberOfItemsToAdd(value)}/>
													</div>
													<div className="col mt-4">
														<CheckoutBtn
															formatedPrice={formattedPrice}
															bikeId={bike.id}
															price={finalPriceWithoutDownPayment}
															isSecondHand={isSecondHand}
															secondHandPrice={secondHandPrice}
															handleSizeClick={startShake}
															disabled={(isAccessory ? false : !selectedSize) && !(variations.length === 1)}
															isLoading={addingToCart} handleClick={handleCheckoutBtnClick}
															stockStatus={bike.stock_status}/>
													</div>
												</div>
											</div>}
										<BookAppointmentButton label={t("Beratung Buchen")} high hoverColor="#212529" bgColor="#212529"
											textColor="#fff"
											color="#fff"
											outline />
										<QAndA setAskAQuestion={() => setAskAQuestion(true)} executeScroll={executeScroll} slug={bike.slug}
											setActive={setActive} questionsLength={questions?.length}/>
										<InfoBox setAdditionalInfoDrawer={setAdditionalInfoDrawer}/>
									</div>
								</div>
							</div>
							<div id="next-description-section"/>
						</div>
					</div>
				</div>
			</div>
			<div className="d-md-none d-block container mb-3 mt-4" ref={checkoutBtnRef}>
				<div className={"row align-items-center g-2"}>
					<div className={"col-4"}>
						<NumberButton defaultValue={1} min={1} max={null} onChange={value => setNumberOfItemsToAdd(value)}/>
					</div>
					<div className={"col"}>
						<CheckoutBtn
							formatedPrice={formattedPrice}
							bikeId={bike.id}
							price={finalPriceWithoutDownPayment}
							isSecondHand={isSecondHand}
							secondHandPrice={secondHandPrice}
							handleSizeClick={startShake}
							disabled={(isAccessory ? false : !selectedSize) && !(variations.length === 1)}
							isLoading={addingToCart}
							handleClick={handleCheckoutBtnClick}
							stockStatus={bike.stock_status}/>
					</div>
				</div>
				<div className="mt-4 d-md-none d-block">
					<QAndA setAskAQuestion={() => setAskAQuestion(true)} executeScroll={executeScroll} slug={bike.slug}
						setActive={setActive} questionsLength={questions?.length} bikeName={bike.name}
					/>
					<InfoBox setAdditionalInfoDrawer={setAdditionalInfoDrawer}/>
					<div id="next-description-section-mobile"/>
					{process.env.NEXT_PUBLIC_STOREFRONT !== "mysofaplan" &&
				<div className="container mb-3 m-auto" style={{height: 1, width: "95%", background: "#DEE2E6"}}/>}
				</div>
				<BookConsultation className="cursor-pointer mt-3"/>
			</div>

			<div className={`pt-3 pt-md-2 mt-md-5 ${variant === "B" ? "bg-light" : "bg-white"}`}>
				<div className="d-md-none d-block" id="next-description-section"/>
				<div className="container">
					<div className="d-md-none d-block d-flex justify-content-between">
						{process.env.NEXT_PUBLIC_STOREFRONT !== "mysofaplan" && <><a
							className="text-decoration-none cursor-pointer pb-2 px-3 d-flex flex-column align-items-center text-center"
							style={{
								background: "#FFFFFF",
								border: "4px solid #F8F8F8",
								borderRadius: "16px",
								width: "48%",
								paddingTop: 12,
								color: "#212529",
								minHeight: 100
							}} onClick={() => {
								reactGA.event({
									category: gaCategories.pdp,
									action: gaEvents.watchLiveQuestions,
									label: gaEvents.watchLiveQuestions,
									nonInteraction: true
								})
								executeScroll()
								setActive(slugify(t("customers_questions").toLowerCase()))
							}}>
							<Image src="/assets/icons/question-mark-box.svg" height={20} width={18}/>
							<Typography className="mt-2" variant="bodySm">{t("questions_and_answers_subsection_text_2")}</Typography>
						</a>
						<div className="col-6 cursor-pointer">
							<CustomerQuestionsSection selectedVariation={selectedVariation} bike={bike} questions={questions}
								isButtonDesign={false}/>
						</div></>}
					</div>
					{!isAccessory && process.env.NEXT_PUBLIC_STOREFRONT !== "mysofaplan" && <div className="d-block d-md-none mt-2 py-3">
						<AboutThisBike isBikeDetails={true} isSecondHand={isSecondHand} bike={bike}/>
					</div>}
					<div ref={refToBikeDescription}>
						<ProductInfo
							variant={variant}
							accordionContent={accordionContent}
							slug={bike.slug}
							tab={tab}
							t={t}
							active={active}
							setActive={setActive}
							isSecondHand={isSecondHand}
							bike={bike}
							isAccessory={isAccessory}/>
					</div>
				</div>
			</div>
			{process.env.NEXT_PUBLIC_STOREFRONT !== "mysofaplan" && <>
				<div className="d-md-none d-block">
					<QAndA setAskAQuestion={() => setAskAQuestion(true)} executeScroll={executeScroll} slug={bike.slug}
						setActive={setActive} questionsLength={questions?.length} bikeName={bike.name}
					/>
				</div>
				<div className="d-md-none d-block">
					<BookConsultation className={`${process.env.NEXT_PUBLIC_STOREFRONT !== "mysofaplan pb-3" && "mb-5"} cursor-pointer mt-4 `}/>
				</div>
				<InfoBox setAdditionalInfoDrawer={setAdditionalInfoDrawer}/>

			</>}
			{!isAccessory && <div className="pt-5"><CrossSellProducts bike={bike} sellProducts={bike.crossSellProducts} bikeName={bike.name}/></div>}
			{isAccessory ? <SimilarBikes similarBikes={bike.similarBikes} isAccessory/> :
				<SimilarBikes similarBikes={bike.similarBikes}/>}
			<div className="mb-md-5 pb-md-5 mt-3 pt-5 mt-md-0 pt-md-0">
				<FindPerfectFurniture/>
			</div>
			<div className="mb-5 mb-md-0 pb-3 pb-md-5">
				<RecentlyViewedProducts/>
			</div>
			<div className="container mt-5 pt-md-4 mt-md-0 d-md-flex">
				<div className="pe-md-5 col-md-6">
					<ComparisonSection/>
				</div>
				<div className="ps-md-5 col-md-6">
					<FAQ/>
				</div>
			</div>
			<MiniPLP />
			{bike.stock_status === "soldout" || soldOut ? <SoldOut upsellIds={bike.upsell_ids}/> : null}
			<AnimationComponent type="fade" inProp={askAQuestion}>
				<div className={"position-fixed top-0 start-0 bottom-0 end-0"} style={{
					backgroundColor: "rgba(0,0,0,0.5)",
					zIndex: 999999
				}} onClick={() => {
					setAskAQuestion(false)
				}}>
					<AskAQuestion setAskAQuestion={setAskAQuestion}/>
				</div>
			</AnimationComponent>
			<AnimationComponent type="fade" inProp={additionalInfoDrawer}>
				<DeliveryAndReturnsSidebar bike={bike} onClose={() => setAdditionalInfoDrawer(false)}/>
			</AnimationComponent>
		</div>
	)
}

BikeDetails.propTypes = {
	bike: PropTypes.any.isRequired
}

export default BikeDetails
