import {useTranslation} from "next-i18next"
import React, {useContext, useState} from "react"
import {colors} from "../../../../colors/Colors"
import LayoutContext from "../../../../context/LayoutContext"
import AccordionAnimation from "../../../reusable/components/AnimationComponent/AccordionAnimation"
import Button from "../../../reusable/components/Button"
import PlusDash from "../../../reusable/PlusDash"
import Typography from "../../../reusable/Typography/Typography"

const FAQ = () => {
	const {t} = useTranslation("common")
	const [loading, setLoading] = useState(false)
	const [showToggle1, setShowToggle1] = useState(false)
	const [showToggle2, setShowToggle2] = useState(false)
	const [showToggle3, setShowToggle3] = useState(false)
	const [showToggle4, setShowToggle4] = useState(false)
	const [showToggle5, setShowToggle5] = useState(false)
	const [showToggle6, setShowToggle6] = useState(false)

	const {isMobile} = useContext(LayoutContext)

	const marginTop = isMobile ? 10 : 16
	const textBold = isMobile ? "text-bold" : ""
	const {primary, white} = colors

	return (
		<div className="col-12 col-lg-10">
			<div className="row align-items-top justify-content-center">
				<Typography variant="bodyLgBlack" semanticTag="h2"
					className="col-12 pb-3 d-block d-md-none" style={{fontSize: 28}}>{t("Häufige Fragen")}</Typography>
				<Typography variant="heading2Black" semanticTag="h2"
					className="col-12 col-lg-8 pb-3 d-none d-md-block text-center">{t("Häufige Fragen")}</Typography>
				<ul className={"col-12 faq-list list-unstyled pt-2 pt-md-5"}>
					<li onClick={() => setShowToggle1(!showToggle1)}
						className={"ps-3 mb-3 mb-md-5  pointer"}
						style={{borderLeft: showToggle1 ? `4px solid ${primary}` : "4px solid #DEE2E6"}}
					>
						<span style={{cursor: "pointer"}} className="row g-2 g-md-3 align-items-top">
							<div className="col-11 overflow-hidden">
								<Typography variant="bodyLgBold" className={textBold}
									semanticTag="h3">{t("Ich verstehe nicht, wie 0 % Zins möglich sind.")}</Typography>
								<AccordionAnimation inProp={showToggle1} duration={400} transTimingFn="ease-out">
									<Typography variant="bodyLg" semanticTag="h4"
										className={`${showToggle1 ? "" : ""} ml-5 mt-3`}>
										Wir arbeiten mit verschiedenen Finanzierungspartnern zusammen. Diese ermöglichen es uns, Dir die Ratenzahlung zinsfrei anzubieten.
										<br/><br/>
										Als Gegenleistung erhalten unsere Finanzierungspartner von uns einen Anteil des Gewinns. Diesen Weg haben wir bewusst gewählt, um Dir Extrakosten zu ersparen. Kontaktiere uns gerne, wenn Du weitere Fragen dazu haben solltest!
									</Typography>
								</AccordionAnimation>
							</div>
							<div className="col-1 mt-1 mt-md-3">
								<PlusDash isOpen={showToggle1}/>
							</div>
						</span>
					</li>
					<li onClick={() => setShowToggle2(!showToggle2)}
						className={"ps-3 mb-3 mb-md-5  pointer"}
						style={{borderLeft: showToggle2 ? `4px solid ${primary}` : "4px solid #DEE2E6"}}
					>
						<span style={{cursor: "pointer"}} className="row g-2 g-md-3 align-items-top">

							<div className="col-11 overflow-hidden mt-2 mt-md-3">
								<Typography variant="bodyLgBold" className={textBold}

									semanticTag="h3">{t("Bezahle ich gleich viel, wie wenn ich alles auf einmal bezahlen würde?")}</Typography>
								<AccordionAnimation inProp={showToggle2} duration={400} transTimingFn="ease-out">

									<Typography variant="bodyLg" semanticTag="h4"
										className={`${showToggle2 ? "" : ""} ml-5 mt-3`}>
										Ja! Du bezahlst mit unseren monatlichen Raten keinen Franken mehr, als wenn Du alles auf einmal bezahlen würdest. Unser 0 %-Finanzierungsangebot ist für Dich völlig zinsfrei.
									</Typography>
								</AccordionAnimation>
							</div>
							<div className="col-1">
								<PlusDash isOpen={showToggle2}/>
							</div>
						</span>
					</li>
					<li onClick={() => setShowToggle3(!showToggle3)}
						className={"ps-3 mb-3 mb-md-5  pointer"}
						style={{borderLeft: showToggle3 ? `4px solid ${primary}` : "4px solid #DEE2E6"}}
					>
						<span style={{cursor: "pointer"}} className="row g-2 g-md-3 align-items-top">

							<div className="col-11 overflow-hidden mt-2 mt-md-3">
								<Typography variant="bodyLgBold" className={textBold}

									semanticTag="h3">{t("Wie kann ich den Monatspreis berechnen?")}</Typography>
								<AccordionAnimation inProp={showToggle3} duration={400} transTimingFn="ease-out">

									<Typography variant="bodyLg" semanticTag="h4"
										className={`${showToggle3 ? "" : ""} ml-5 mt-3`}>
										Das ist ganz einfach! Nimm einfach den Gesamtpreis und teile ihn durch die gewünschte Laufzeit des Vertrags.
										<br/><br/>
										Beispiel:
										<br/><br/>
										{t("Gesamtpreis: CHF 4’320.")}
										<br/><br/>
										{t("Dauer des Plans: 36 Monate")}
										<br/><br/>
										{t("Monatsrate: CHF 120 (4’320/36)")}
										<br/><br/>
									</Typography>
								</AccordionAnimation>
							</div>
							<div className="col-1">
								<PlusDash isOpen={showToggle3}/>
							</div>
						</span>
					</li>
					<li onClick={() => setShowToggle4(!showToggle4)}
						className={"ps-3 mb-3 mb-md-5  pointer"}
						style={{borderLeft: showToggle4 ? `4px solid ${primary}` : "4px solid #DEE2E6"}}>
						<span style={{cursor: "pointer"}} className="row g-2 g-md-3 align-items-top">

							<div className="col-11 overflow-hidden mt-2 mt-md-3">

								<Typography variant="bodyLgBold" className={textBold}

									semanticTag="h3">{t("Wann ist die erste Rate fällig?")}</Typography>
								<AccordionAnimation inProp={showToggle4} duration={400} transTimingFn="ease-out">

									<Typography variant="bodyLg" semanticTag="h4"
										className={`${showToggle4 ? "" : ""} ml-5 mt-3`}>
										Nach Vertragsunterzeichnung werden Dich innerhalb von 1-2 Wochen die Einzahlungsscheine erreichen. Die erste Rate ist jeweils aber erst am ersten Tag des übernächsten Monats fällig.
										<br/><br/>
										Beispiel: Du unterzeichnest den Vertrag am 15. Oktober. Die erste Rate wird somit am 1. Dezember fällig sein.
									</Typography>
								</AccordionAnimation>
							</div>
							<div className="col-1">
								<PlusDash isOpen={showToggle4}/>
							</div>
						</span>
					</li>
					<li onClick={() => setShowToggle5(!showToggle5)}
						className={"ps-3 mb-3 mb-md-5  pointer"}
						style={{borderLeft: showToggle5 ? `4px solid ${primary}` : "4px solid #DEE2E6"}}>
						<span style={{cursor: "pointer"}} className="row g-2 g-md-3 align-items-top">
							<div className="col-11 overflow-hidden" style={{marginTop}}>
								<Typography variant="bodyLgBold" className={textBold}

									semanticTag="h3">Werden meine Möbel zu mir nach Hause geliefert?</Typography>
								<AccordionAnimation inProp={showToggle5} duration={400} transTimingFn="ease-out">

									<Typography variant="bodyLg" semanticTag="h4"
										className={`${showToggle5 ? "" : ""} ml-5 mt-3`}>Ja! Wir liefern Dir Deine Möbel bequem und stressfrei bis vor die Haustür.</Typography>
								</AccordionAnimation>
							</div>
							<div className="col-1 mt-1 mt-md-3">
								<PlusDash isOpen={showToggle5}/>
							</div>
						</span>
					</li>
				</ul>
				<div className="col-12 d-block d-md-none">
					<div className="row justify-content-center">
						<div className="col-12">
							<Button label={t("Siehe vollständige FAQ")}
								outline
								textColor="#212529"
								hoverColor="#212529"
								color="#fff"
								paddingY="3"
								href={"/faq/konzept"} className="w-100"
								isLoading={loading} onClick={() => {
									setLoading(true)
								}}/>
						</div>
					</div>
				</div>
				<div className="col-12 d-none d-md-block">
					<div className="row">
						<div className="col-12 d-flex justify-content-center">
							<Button label={t("Siehe vollständige FAQ")} outline
								paddingY="3"
								textColor="#212529"
								hoverColor="#212529"
								color="#fff"
								href={"/faq/konzept"} className="w-auto"
								isLoading={loading} onClick={() => {
									setLoading(true)
								}}/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FAQ
