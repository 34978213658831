/* eslint-disable complexity */
import React, {forwardRef, useContext, useEffect, useState} from "react"
import Link from "next/link"
import BikeCollectionItemLoadingOverlay from "../BikeCollectionItemLoadingOverlay"
import Price from "./Price"
import TotalPrice from "./TotalPrice"
import Typography from "../../reusable/Typography/Typography"
import CollectionItemBadge from "./CollectionItemBadge"
import CustomSlider from "../../imageSlides/CustomSlider"
import {isOccasionBike} from "../../../utility/Helper"
import ComparisonCheckboxButton from "./ComparisonCheckboxButton"
import Image from "next/image"
import StockWarning from "./StockWarning"
import {useTranslation} from "next-i18next"
import ProductAvailableStatus from "./ProductAvailabelStatus"
import ProductColors from "./ProductColors"
import YourDownPayment from "../../reusable/YourDownPayment"
import usePrice from "../../../hooks/usePrice"
import {content} from "../../../constants/Instances"
import {BikeType} from "../../../utility/Types"
import WishListContext from "../../../context/WishListContext"
import Button from "../../reusable/components/Button"
import DataContext from "../../../context/DataContext"

type BadgeProps = {
    is2023: boolean
    is2022: boolean
    isNew: boolean
    kilometers: boolean
    occasion: boolean
    onSale: boolean
    isBikeOfTheMonth: boolean
    isInStock: boolean
    isNonEBike: boolean
	discountKey: string
	isFeatured:boolean
}

type Props = {
  productUrl: string
  selected: boolean
  soldOut: boolean
  variations: any
  dImageSlides: boolean
  attachments: string[]
  bikeWeight: number
  brand: string
  stockInfo: string
  loadingVariations: boolean
  loading: boolean
  regularPrice: string
  bike: any
  badgeProps: BadgeProps
  setDImageSlides: React.Dispatch<React.SetStateAction<boolean>>
  handleMouseEnter: ()=> void
  handleMouseLeave: ()=> void
  handleClick?: React.MouseEventHandler<HTMLElement>
  secondHandPrice: number
  isRowForMobile?: boolean,
  cardWidth?: string,
  isTwoCardsView?: boolean,
  withArrows?: boolean,
  isCarouselElement?: boolean,
  withOneImage?: boolean,
  isWishList?: boolean,
	mainCategory?: string
	isRecommended?: boolean
}

const CollectionItemGridView = forwardRef<HTMLElement, Props>(({
	secondHandPrice,
	productUrl,
	brand,
	isWishList,
	selected,
	soldOut,
	loading,
	regularPrice,
	isRecommended,
	bike,
	badgeProps,
	isCarouselElement,
	handleMouseEnter,
	handleMouseLeave,
	handleClick,
	isRowForMobile,
	cardWidth = "288px",
	isTwoCardsView,
	withArrows,
	withOneImage,
	mainCategory
}, ref) => {
	const {isBikeOfTheMonth, isFeatured, is2023, is2022, isNew, kilometers, occasion, isInStock, isNonEBike, discountKey} = badgeProps
	const isSecondHand = isOccasionBike(bike)
	const hasBadge = isFeatured || isBikeOfTheMonth || is2023 || is2022 || isNew || kilometers || occasion || isInStock || isNonEBike
	const isMySofaPlan = process.env.NEXT_PUBLIC_STOREFRONT === "mysofaplan"
	const [showArrows, setShowArrows] = useState<boolean>(false)
	const {comparisonProducts} = useContext(DataContext)
	const isActive = comparisonProducts.includes(bike.slug)
	const {onSale, finalPriceWithoutDownPayment, organizationSlug, downPayment} = usePrice(bike)
	const {totalPhysicalStock} = bike
	const {t} = useTranslation("common")
	const handleShowArrowsMouseEnter = () => {
		setShowArrows(true)
	}

	const handleHideArrowsMouseLeave = () => {
		setShowArrows(false)
	}

	const {addToWishList, getWishListItemBySlug, deleteWishListItemBySlug} = useContext(WishListContext)
	const [isBookmarked, setIsBookmarked] = useState<BikeType>(null)
	const handleDeleteBookmark = e => {
		e.preventDefault()
		e.stopPropagation()
		deleteWishListItemBySlug(bike.slug)
		const bikeFoundInBookmark = getWishListItemBySlug(bike.slug)
		setIsBookmarked(bikeFoundInBookmark)
	}

	const handleAddBookmark = e => {
		e.preventDefault()
		e.stopPropagation()
		addToWishList(bike)
		const bikeFoundInBookmark = getWishListItemBySlug(bike.slug)
		setIsBookmarked(bikeFoundInBookmark)
	}

	useEffect(() => {
		setIsBookmarked(getWishListItemBySlug(bike.slug))
	}, [handleDeleteBookmark, handleAddBookmark])

	const showPartnershipExclusiveText = Boolean(bike?.customInstanceDiscounts?.[organizationSlug])
	return (
		<>{isRowForMobile ? <Link href={productUrl} passHref={true}>
			<a className={`link-reset ${isTwoCardsView ? "p-1" : "p-2"} card card-item ${selected ? "card-shadow" : null}`}
				onClick={handleClick} style={{
					zIndex: selected ? 9999 : null,
					opacity: soldOut ? 0.5 : null,
					transition: "transform 0.2s",
					transformOrigin: "center center",
					minHeight: isTwoCardsView ? "100%" : 400,
					pointerEvents: soldOut ? "none" : "auto",
					borderRadius: "24px",
					backgroundColor: "#fff",
					width: isTwoCardsView ? "100%" : "90%",
					minWidth: isTwoCardsView ? cardWidth : "90%",
					border: isFeatured ? "1px solid #B9DBAA" : "1px solid #DEE2E6",
					cursor: "pointer"
				}}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<div>
					<div style={{
						borderRadius: isTwoCardsView ? 32 : 16,
						backgroundColor: "#fff",
						marginBottom: "2px",
						width: "100%"
					}} className="d-flex flex-column position-relative text-start">
						{isBookmarked ? <div style={{width: "40px"}} onClick={handleDeleteBookmark}> <img className="bookmark" src={"/assets/icons/Heart-gold.svg"} alt="Wish list icon" /></div> : <div style={{width: "40px"}} onClick={handleDeleteBookmark}><img onClick={handleAddBookmark} className="bookmark" src={"/assets/icons/Heart.svg"} alt="Wish list icon" /> </div>}

						{hasBadge && <div
							className="ps-0 d-flex align-items-start z-index"
							style={{marginTop: -25, marginLeft: 0}}>
							<CollectionItemBadge
								isBikeOfTheMonth={isBikeOfTheMonth}
								isNonEBike={isNonEBike}
								is2023={is2023}
								is2022={is2022}
								isNew={isNew}
								isFeatured={isFeatured}
								inStock={false}
								kilometers={kilometers}
								occasion={occasion}
								onSale={onSale}
								soldOut={soldOut}
								discountKey={discountKey}
							/>

						</div>}
						<div style={{width: "100%"}} className={`mx-auto ${isTwoCardsView ? "p-1" : "p-0"}`}>
							{withOneImage ? <Image style={{borderRadius: 8}} src={bike.images[0].src} alt={bike.name} width={288} height={288}
								objectFit={"cover"}/> :
								<CustomSlider dots={!withArrows} arrows={withArrows} productUrl={productUrl}
									slidesList={bike.images}/>
							}
							<StockWarning amount={totalPhysicalStock as number} />
						</div>
					</div>
				</div>
				<div
					className="mt-3 d-flex flex-column px-2 text-start justify-content-center">
					<div className={"my-2 "}>
						<div>
							<Typography variant={isTwoCardsView ? "bodyXSmBold" : "bodyLgBlack"} semanticTag="h4"
								className="mb-1"
								style={{fontSize: 12, width: "100%"}}>
								{bike.name}
							</Typography>
							<div
								className={` d-flex ${isTwoCardsView ? "flex-row mb-2" : "mb-4"} justify-content-between align-items-center`}>
								<Typography variant={isTwoCardsView ? "bodyXSm" : "bodySm"} semanticTag="span"
								>
									{mainCategory}
								</Typography>
							</div>
						</div>
						<div>
							<Price price={bike.uvpPrice} discountedPrice={finalPriceWithoutDownPayment}
								onSale={onSale} isSecondHand={isSecondHand}
								secondHandPrice={bike.displayPrice} isRowForMobile
								priceFontSize={24}/>
							<TotalPrice price={bike.uvpPrice} onSale={onSale} listView isSecondHand={isSecondHand}
								secondHandPrice={bike.displayPrice} discountedPrice={finalPriceWithoutDownPayment}
								priceVariant={isSecondHand || onSale ? "bodyXSmBoldMdBodySmBold" : "bodySm"} />
							{/* Show DownPayment in the product card: */}
							<YourDownPayment onSale={onSale} downPayment={downPayment} isSecondHand={isSecondHand}
								variant={ "bodyXSm" }/>
						</div>
						<div className="mt-3">
							<div className="d-flex justify-content-between align-items-center">
								<Typography variant="bodyXSm" semanticTag="p">{isMySofaPlan ? brand : mainCategory} { }</Typography><div
									className={` d-flex ${isTwoCardsView ? "flex-column mb-2" : "mb-4"} justify-content-between align-items-center`}>
									<Typography variant={isTwoCardsView ? "bodyXSm" : "bodySm"} semanticTag="span">
										{mainCategory}
									</Typography>
									<ProductAvailableStatus stockStatus={bike?.stock_status}
										withHoverEffect={true}/>
								</div></div>

							<ProductColors colors={bike.colorData}/>
						</div>
					</div>
					<div className="col-12">
						{isWishList ? <div onClick={handleDeleteBookmark} className="p-2 d-flex justify-content-center" ><Typography style={{fontSize: "12px"}} variant="bodySm" semanticTag="span">{t("remove-from-wishlist")}</Typography></div> : <ComparisonCheckboxButton slug={bike.slug}/> }
					</div>
				</div>
				{loading ? <BikeCollectionItemLoadingOverlay/> : null}
			</a>
		</Link> :
			<Link href={productUrl} passHref={true}>
				<a className={"link-reset"} onClick={handleClick}>
					<article style={{
						position: !isCarouselElement && !isWishList && selected ? "absolute" : isWishList ? "relative" : null,
						zIndex: selected ? 100 : null,
						opacity: soldOut ? 0.5 : null,
						transition: "all 0.2s",
						transformOrigin: "center center",
						pointerEvents: soldOut ? "none" : "auto",
						borderRadius: "24px",
						minHeight: bike?.customInstanceDiscount ? 532 : "474px",
						width: cardWidth,
						padding: isTwoCardsView ? "1rem" : " 8px",
						cursor: "pointer",
						border: isActive ? "1px solid #2CA58D" : isFeatured ? "1px solid #FEC04D" : "1px solid #DEE2E6"
					}}
					className={`${selected ? "collection-grid-view-item" : null}  card  card-item ${selected ? "card-shadow" : null}`}
					onMouseEnter={() => {
						handleMouseEnter()
						handleShowArrowsMouseEnter()
					}}
					onMouseLeave={() => {
						handleMouseLeave()
						handleHideArrowsMouseLeave()
					}}
					>
						<div className="p-0">
							<div style={{
								borderRadius: isTwoCardsView ? 32 : 16,
								backgroundColor: "#fff",
								padding: "5px",
								position: "relative"

							}} className="d-flex flex-column text-start">
								{isBookmarked ? <img onClick={handleDeleteBookmark} className="bookmark" src={"/assets/icons/Heart-gold.svg"} alt="Wish list icon" /> : <img onClick={handleAddBookmark} className="bookmark" src={"/assets/icons/Heart.svg"} alt="Wish list icon" />}

								{hasBadge && <div
									className="position-absolute  d-flex align-items-start z-index"
								>
									<CollectionItemBadge
										isNonEBike={isNonEBike}
										isBikeOfTheMonth={isBikeOfTheMonth}
										is2023={is2023} is2022={is2022}
										isNew={isNew}
										isFeatured={isFeatured}
										inStock={bike.stock_status === "instock"}
										kilometers={kilometers} occasion={occasion}
										onSale={onSale}
										discountKey={discountKey}
										soldOut={soldOut}
										itemsLeft={totalPhysicalStock as number}
									/>
								</div>}
								<div style={{width: "96%", borderRadius: "1rem"}} className="mx-auto">
									{withOneImage ?
										<Image style={{borderRadius: 8}} src={bike.images[0].src} alt={bike.name} width={288} height={288}
											objectFit={"cover"}/> :
										<CustomSlider dots={selected} arrows={selected} productUrl={productUrl}
											slidesList={bike.images}/>
									}
									<StockWarning amount={totalPhysicalStock as number} />
								</div>
							</div>
						</div>
						<div style={{padding: "16px", minHeight: "50px"}} className="mt-3 d-flex flex-column text-start justify-content-center card-item-price-section">
							<div className="d-flex justify-content-start align-items-center gap-3 flex-wrap">
								{isRecommended ? <div style={{padding: "2px 8px", background: "#DEE2E6", borderRadius: "8px"}} className="d-flex justify-conten-start align-items-center gap-2"><Image src="/assets/icons/sparkle.svg" width={12} height={12} /><Typography style={{fontSize: "12px", lineHeight: "16px"}} variant="bodySmBold">{ t("recommended-for-you")}</Typography></div> : null}
								{process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationSlug && showPartnershipExclusiveText && <div className="d-flex align-items-start gap-2">
									<img src={content[organizationSlug]?.companyIcon} width={"auto"} height={18}/>
									<Typography style={{marginTop: -2}} variant={ "bodyXSm"} semanticTag="span">
										{t(content[organizationSlug]?.listingItem?.text).replace("instance", content[organizationSlug]?.companyName)}
									</Typography>
								</div>}</div>
							<Typography variant={isTwoCardsView ? "bodySmBold" : "bodyXSmBold"}
								semanticTag="div"
								className="mb-1"
								style={{fontSize: isTwoCardsView ? 16 : 14, width: "100%", lineHeight: "20px"}}>
								{bike.name}
							</Typography>
							<div className={`${isTwoCardsView ? "mb-3" : "mt-2"} `}>
								<Price price={bike.uvpPrice} discountedPrice={finalPriceWithoutDownPayment}
									onSale={onSale} isSecondHand={isSecondHand}
									secondHandPrice={bike.displayPrice} isRowForMobile
									priceFontSize={24}/>
								<TotalPrice price={bike.uvpPrice} onSale={onSale} listView isSecondHand={isSecondHand}
									secondHandPrice={bike.displayPrice} discountedPrice={finalPriceWithoutDownPayment}
									priceVariant={isTwoCardsView ? "bodyXSm" : bike?.customInstanceDiscount || isSecondHand || onSale ? "bodyXSmBoldMdBodySmBold" : "bodySm"}/>
								{/* Show DownPayment in the product card: */}
								<YourDownPayment onSale={onSale} downPayment={downPayment} isSecondHand={isSecondHand}
									variant={ "bodyXSm" }/>
							</div>
							<div className="mt-3">
								<div className="d-flex justify-content-between align-items-center">
									<Typography variant="bodyXSm" semanticTag="p">{isMySofaPlan ? brand : mainCategory} { }</Typography><div
										className={` d-flex ${isTwoCardsView ? "flex-column mb-2" : "mb-4"} justify-content-between align-items-center`}>
										<Typography variant={isTwoCardsView ? "bodyXSm" : "bodySm"} semanticTag="span">
											{mainCategory}
										</Typography>
										<ProductAvailableStatus stockStatus={bike?.stock_status}
											withHoverEffect={true}/>
									</div></div>

								<ProductColors colors={bike.colorData}/>
							</div>
							{selected && !isWishList ? <div className={"d-none d-md-block "}>
								<div className={`col-12 ${!isTwoCardsView && "mt-3 mb-2"}`} style={{height: 1, background: "#DEE2E6"}}/>
								<div className="col-12 "><ComparisonCheckboxButton slug={bike.slug}/></div>
							</div> : isWishList ? <div onClick={handleDeleteBookmark} className=" d-flex justify-content-center pt-3"> <Button btnFontSize={12} label={t("remove-from-wishlist")} bgColor="transparent" textColor="black" color="secondary" outline /> </div> : null}
						</div>
						{loading ? <BikeCollectionItemLoadingOverlay/> : null}
					</article>
				</a>
			</Link>
		}
		</>
	)
})

export default CollectionItemGridView

CollectionItemGridView.displayName = "CollectionItemGridView"
