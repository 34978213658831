import React, {useContext, useState} from "react"
import Image from "next/image"
import {useTranslation} from "next-i18next"
import Typography from "../reusable/Typography/Typography"
import PropTypes from "prop-types"
import styles from "./bikeDetails.module.scss"
import PDPTooltip from "./PDPTooltip"
import {ModalContext} from "../../context/ModalContext"
import ConsultBookingModalContent from "../reusable/ModalsContent/ConsultBookingModalContent"

type Props = {
	setAdditionalInfoDrawer: (state: boolean) => void
}

const InfoBox: React.FC<Props> = ({setAdditionalInfoDrawer}) => {
	const {t} = useTranslation("productDescrPage")
	const {setModalContent, openModal, closeModal} = useContext(ModalContext)
	const [isOpen, setIsOpen] = useState(false)
	const [activeTooltip, setActiveTooltip] = useState("")

	const handleTooltipClick = label => {
		setIsOpen(!isOpen)
		setActiveTooltip(label)
	}

	return (
		<>

			<div className={"pt-sm-4 pb-sm-3 py-4 py-md-0 rounded py-md-4 mb-3 border-top mt-md-4"}>
				<div className="row justify-content-between">
					<div className="col-12 col-md-4 px-3 d-flex justify-content-between flex-md-column align-items-center align-items-md-start justify-content-md-start mb-1">
						<div className="d-flex align-items-center d-md-block">
							<div className="d-md-none d-flex">
								<Image className="cursor-pointer d-md-none d-flex" src="/assets/icons/delivery-mysofaplan.svg" alt="car icon" width={24} height={24} />
							</div>
							<div className="d-none d-md-flex align-items-center">
								<Image className="cursor-pointer" src="/assets/icons/delivery-mysofaplan.svg" alt="car icon" width={40} height={40} />
							</div>
							<div className="d-flex ms-3 ms-md-0 flex-md-column align-items-center align-items-md-start mt-md-3 gap-3 justify-content-evenly">
								<Typography variant="bodySm" semanticTag="span">{t("free-delivery")} </Typography>
								<Image
									className="cursor-pointer d-none d-md-flex align-items-center"
									onClick={() => handleTooltipClick("service-shipping")}
									src={
										isOpen && activeTooltip === "service-shipping" ?
											process.env.NEXT_PUBLIC_STOREFRONT === "mysofaplan" ?
												"/assets/icons/info-active-brown.svg" :
												"/assets/icons/info-active.svg" :
											"/assets/icons/info-transparent.svg"
									}
									alt="tools icon"
									width={24}
									height={24}
								/>
							</div>
						</div>
						<Image
							className="cursor-pointer d-block d-md-none"
							onClick={() => handleTooltipClick("service-shipping")}
							src={
								isOpen && activeTooltip === "service-shipping" ?
									process.env.NEXT_PUBLIC_STOREFRONT === "mysofaplan" ?
										"/assets/icons/info-active-brown.svg" :
										"/assets/icons/info-active.svg" :
									"/assets/icons/info-transparent.svg"
							}
							alt="tools icon"
							width={24}
							height={24}
						/>
					</div>

					<div className="col-12 col-md-4 px-3 py-2 py-md-0 mb-1">
						<div className="d-flex  align-items-center ">
							<div className="col-10 col-md-12 d-flex flex-md-column align-items-center">
								<div className="col-auto me-3 d-flex d-md-none">
									<Image className="cursor-pointer" src="/assets/icons/return-mysofaplan.svg" alt="tools icon" width={24} height={24}/>
								</div>
								<div className="col-12 d-none d-md-flex align-items-center">
									<Image className="cursor-pointer" src="/assets/icons/return-mysofaplan.svg" alt="tools icon" width={40} height={40}/>
								</div>
								<div className="col-12 mt-md-3">
									<Typography variant="bodySm" semanticTag="span">{t("cancel-and-exchange-policy")} </Typography>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-4 px-3 mb-1">
						<div className="d-md-row d-flex flex-md-column gx-3 align-items-center align-items-md-start justify-content-between justify-content-md-start">
							<div className="col-10 col-md-12 mb-2 d-flex flex-md-column  align-items-center ">
								<div className="col-auto me-3 d-flex align-items-center d-md-none">
									<Image className="cursor-pointer" src="/assets/icons/garantie-mysofaplan.svg" alt="tools icon" width={24}height={24}/>
								</div>
								<div className="col-12 d-none d-md-flex align-items-center">
									<Image className="cursor-pointer" src="/assets/icons/garantie-mysofaplan.svg" alt="tools icon" width={40}height={40}/>
								</div>
								<div className="col-12 mt-md-3">
									<Typography variant="bodySm" semanticTag="span">{t("guarantee-and-service-stores")} </Typography>
								</div>
							</div>
							{isOpen && activeTooltip === "guarantee" ?
								<Image className="cursor-pointer col-1" onClick={() => handleTooltipClick("guarantee")} src={"/assets/icons/i fo-active.svg"} alt="tools icon" width={24} height={24}/> :
								<Image className="cursor-pointer col-2" onClick={() => handleTooltipClick("guarantee")} src={"/assets/icons/info-transparent.svg"} alt="tools icon" width={24} height={24}/>
							}
						</div>
					</div>

					<div className="position-relative ">
						<PDPTooltip isOpen={isOpen} setIsOpen={setIsOpen} desktop label={activeTooltip}/>
					</div>
				</div>
				<button className={`d-flex align-items-center justify-content-between reset border-0 mt-0 mt-md-4 py-3 py-md-2 px-3 px-md-3 col-12 col-md-auto ${styles.infoBoxQuestionButton}`}
					onClick={() => {
						setModalContent(<ConsultBookingModalContent closeModal={closeModal}/>)
						openModal()
					}}>
					<div className="d-flex">
						<div className="d-flex d-md-none">
							<Image src={"/assets/icons/person.svg"} layout={"intrinsic"} objectFit={"contain"} width={20} height={20}/>
						</div>
						<Typography className="py-md-1 ms-3 ms-md-0" variant="bodySmBold">{t("have-a-question")}</Typography>
					</div>
					<Image className="cursor-pointer col-2" style={{transform: "rotate(90deg)"}} src={"/assets/icons/chevron-up-icon.svg"} alt="arrow icon" width={14} height={14}/>
				</button>

			</div>
		</>
	)
}

InfoBox.propTypes = {
	setAdditionalInfoDrawer: PropTypes.func.isRequired
}

export default InfoBox
