import React from "react"

export type OrganizationContextProviderState = {
	organizationSlug: string | null,
	organizationInitialized: boolean
}

const OrganizationContext = React.createContext<OrganizationContextProviderState>({
	organizationSlug: null,
	organizationInitialized: false
})

export default OrganizationContext
