import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import CartContext, {CartItem} from "../context/CartContext"

const localStorageItemName = "cart"
const localStorageCartStatus = "cartCountDown"
export const timerStart = 1200

type Props = {
    children: React.ReactNode
}

const storeCart = (items: CartItem[]) => {
	if (typeof window !== undefined) {
		localStorage.setItem(localStorageItemName, JSON.stringify(items))
	}
}

export const getCart = () => {
	if (typeof window !== undefined) {
		const cart = localStorage.getItem(localStorageItemName)
		if (cart !== null) {
			return JSON.parse(cart)
		}

		return []
	}

	return []
}

export const getCartStatus = () => {
	if (typeof window !== undefined) {
		const cart = localStorage.getItem(localStorageCartStatus)
		if (cart !== null) {
			return JSON.parse(cart)
		}
	}

	return {
		count: "0",
		time: "00:00:00",
		startTimer: timerStart
	}
}

const CartContextProvider: React.FC<Props> = ({children}) => {
	const [items, setItems] = useState<CartItem[]>([])

	useEffect(() => {
		setItems(getCart())
	}, [])

	const addItem = (item: CartItem) => {
		setItems(prevItems => [
			...prevItems,
			item
		])
	}

	const removeItem = (item: CartItem) => {
		// Redistribute down payment of removed item to all other items

		let amountToAddToEveryItem = 0
		for (const item of items) {
			amountToAddToEveryItem = item.downPayment ? item.downPayment / (items.length - 1) : 0
		}

		setItems(prevItems => {
			const _items = [...prevItems]
			_items.splice(_items.indexOf(item), 1)

			// If items are still in cart, add distributed amount of down payment of removed item
			if (_items.length) {
				return _items.map(_item => {
					return {
						..._item,
						downPayment: _item.downPayment + amountToAddToEveryItem
					}
				})
			}

			return _items
		})
	}

	const updateItem = (item: CartItem, updatedItem: CartItem) => {
		setItems(prevItems => {
			const _items = [...prevItems]
			_items[_items.indexOf(item)] = updatedItem
			return _items
		})
	}

	useEffect(() => {
		storeCart(items)
	}, [items])

	const clearCart = () => {
		setItems([])
		storeCart([])
	}

	return (
		<CartContext.Provider value={{items, setItems, addItem, removeItem, storeCart, updateItem, clearCart}}>
			{children}
		</CartContext.Provider>
	)
}

CartContextProvider.propTypes = {
	children: PropTypes.node
}

export default CartContextProvider

