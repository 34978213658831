export const colors = {
	primary: "#2B6552",
	secondary: "#6C7074",
	darkBlue: "#090740",
	success: "#23A455",
	light: "#F8F8F8",
	dark: "#212529",
	danger: "red",
	white: "#ffffff",
	darkBlack: "#212529"
}
