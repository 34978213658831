import React, {Dispatch, SetStateAction, useContext} from "react"
import {useTranslation} from "next-i18next"
import DownPayment from "../plan/Downpayment"
import Plan from "../plan/Plan"
import CheckoutContext from "../../../context/CheckoutContext"
import DurationSlideHeader from "./DurationSlideHeader"
import OnSaleSlideHeader from "./OnSaleSlideHeader"
import PriceTooltipWithCheckoutBtn from "./PriceTooltipWithCheckoutBtn"
import usePrice from "../../../hooks/usePrice"

type Props = {
	bikeId?: number
	secondHandPrice?: number
	isOnSale?: boolean
	planLength?: any
	initialPrice?: any
	formatedPrice?: any
	customInstanceDiscount?: number
	product: any
	isSecondHand: boolean
	isMobile: boolean
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	disabled?: boolean
	isLoading?: boolean
	handleClick?: any
	isAccessory?: boolean
	handleSizeClick?: any
	isHorizontalDownpayment?: boolean
	PriceInputBesidesTitle?: boolean
	IsPDPDownpayment?: boolean
	priceInputBesidesTitle?: boolean
	hasPlaceHolder?: boolean
}

const DurationAndDownpayment: React.FC<Props> = ({product,
	priceInputBesidesTitle, IsPDPDownpayment,
	customInstanceDiscount,
	isHorizontalDownpayment,
	initialPrice,
	isAccessory,
	planLength,
	disabled,
	handleClick,
	handleSizeClick,
	hasPlaceHolder,
	isOnSale, isLoading, isSecondHand, isMobile, isOpen, setIsOpen}) => {
	const {t} = useTranslation()
	const {data} = useContext(CheckoutContext)
	const checkoutData = data
	const {finalPriceWithoutDownPayment, finalPriceWithDownPayment, downPayment} = usePrice(product)

	return (
		<div className={"pt-mb-3 px-3 py-4 bg-light"} style={{borderRadius: "16px"}}>
			{isOnSale ?
				<OnSaleSlideHeader
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					checkoutData={checkoutData}
					resultingPrice={finalPriceWithDownPayment}
					bikePrice={finalPriceWithoutDownPayment}
					initialPrice={product.uvpPrice || product.price}
					t={t}
				/> :
				<DurationSlideHeader
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					checkoutData={checkoutData}
					resultingPrice={finalPriceWithDownPayment}
					t={t}
				/>
			}
			<Plan payOneTime hidePayOneTimeLabel isMobile={isMobile} isSecondHand={isSecondHand}/>

			<div className={`${checkoutData?.planLength === 1 && "d-none"}`}>
				<hr/>
				<DownPayment bikeId={product.id} min={0} max={finalPriceWithoutDownPayment} hasPlaceholder={hasPlaceHolder} IsPDPDownpayment={IsPDPDownpayment} PriceInputBesidesTitle={priceInputBesidesTitle} isHorizontalDownpayment={isHorizontalDownpayment}/>
			</div>
			{process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && customInstanceDiscount ?
				<PriceTooltipWithCheckoutBtn
					product={product}
					isOnSale={isOnSale}
					isSecondHand={isSecondHand}
					disabled={disabled}
					isLoading={isLoading}
					handleClick={handleClick}
					isAccessory={isAccessory}
					handleSizeClick={handleSizeClick}
					planLength={planLength}
					initialPrice={initialPrice}
					checkoutData={checkoutData} customInstanceDiscount={customInstanceDiscount}
				/> :
				null}

		</div>
	)
}

export default DurationAndDownpayment
