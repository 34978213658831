import React, {useEffect, useRef, useState} from "react"
import {useTranslation} from "next-i18next"
import Image from "next/image"
import {useRouter} from "next/router"
import Slider, {CustomArrowProps, Settings} from "react-slick"
import Link from "next/link"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {ImageDescription, ImageGalary, ShowMoreOverlay} from "./components"
import {ChevronLeft, ChevronRight} from "react-bootstrap-icons"
import ComparisonCheckboxButton from "../bikesCollection/BikesCollectionItem/ComparisonCheckboxButton"
import WhatsappSlide from "./components/WhatsappSlide"
import Typography from "../reusable/Typography/Typography"

interface SlideComponent {

}

type ImageType = {
	alt: string
	description: string
	id: string
	name: string
	src: string
}

type Props = {
	slidesList: any[],
	arrows?: boolean,
	dots?: boolean
	bike?: any
	slidesToShow?: number
	withGalery?: boolean
	limitItemsToRender?: number,
	showMoreButton?: boolean,
	goNext?: boolean,
	showDescription?: boolean,
	productUrl?: string,
	hasComparisonBtn?: boolean
	hasWhatsappSlide?: boolean,
	isAccessory?: boolean,
	isHoverArrows?: boolean,
	showArrows?: boolean
	// FeaturedNumber?: number,
	// featured?: boolean
}

const newImage = {
	id: "whatsapp-call",
	alt: "Whatsapp",
	description: "whatsapp slide",
	src: "/assets/icons/whats-app-button-black-icon.svg"
}
const CustomSlider: React.FC<Props> = ({slidesList, hasComparisonBtn, bike, arrows, dots, slidesToShow = 1, withGalery, limitItemsToRender, showMoreButton, goNext, productUrl, hasWhatsappSlide, showDescription, isAccessory, isHoverArrows, showArrows// Featured,
}) => {
	const {t} = useTranslation("common")
	const router = useRouter()

	const sliderRef = useRef<Slider>()
	const [activeSlideIndex, setActiveSlideIndex] = useState(0)
	const slidesListToRender = limitItemsToRender && slidesList.length > limitItemsToRender ? slidesList.slice(0, limitItemsToRender) : slidesList
	const updatedSlidesToRender = [...slidesListToRender, newImage]
	const lastImageOfSlider = slidesListToRender[slidesListToRender.length - 1]?.src
	const handleGoTo = (slideNumber: number) => sliderRef.current?.slickGoTo(slideNumber)

	useEffect(() => {
		if (goNext && activeSlideIndex === 0 && slidesListToRender.length > 1) {
			handleGoTo(1)
		} else {
			handleGoTo(0)
		}
	}, [goNext, bike])

	useEffect(() => {
		handleGoTo(0)
		setActiveSlideIndex(0)
	}, [bike])

	const handleAllPhotosClick = e => {
		e.stopPropagation()
		router.push(productUrl)
	}

	interface ArrowProps extends CustomArrowProps {
		direction: "left" | "right"
	}

	function CustomArrow({onClick, direction}: ArrowProps) {
		const handleClick = (e: React.MouseEvent) => {
			e.stopPropagation()
			e.preventDefault()
			onClick(e)
		}

		const styles = direction === "right" ? {right: "-3%"} : {left: "-3%"}

		return (
			<div
				className={"rounded-circle text-dark p-2 position-absolute shadow-sm pointer"}
				style={{background: "#212529CC", opacity: "0,8", top: "48%", transform: "translateY(-50%)", width: "32px", height: 32, zIndex: 1, ...styles}}
				onClick={handleClick}
			>
				{direction === "right" ? <ChevronRight color="white" size={20}/> :
					<ChevronLeft color="white" size={20}/>}
			</div>
		)
	}

	const sliderConfig: Settings = {
		infinite: true,
		speed: 500,
		slidesToShow,
		slidesToScroll: 1,
		dots,
		dotsClass: "custom-dots",
		arrows,
		focusOnSelect: false,
		beforeChange: (_, next) => setActiveSlideIndex(next),
		prevArrow: <CustomArrow direction="left"/>,
		nextArrow: <CustomArrow direction="right"/>
	}
	return (
		<div className={`bg-white ${withGalery && "d-flex justify-content-between"}`} style={{marginRight: withGalery ? 72 : ""}}>
			{withGalery &&
			<div>
				<ImageGalary activeSlideIndex={activeSlideIndex} goToSlide={handleGoTo} images={hasWhatsappSlide ? updatedSlidesToRender : slidesListToRender}/>
			</div>}
			<div style={{width: withGalery ? "83%" : ""}}>
				<Slider ref={sliderRef} {...sliderConfig}>
					{(hasWhatsappSlide ? updatedSlidesToRender : slidesListToRender).map(({alt, id, description, src}, i) => (
						<div key={id} className="position-relative bike-item">
							{showDescription && i === 0 && <ImageDescription description={description}/>}
							{(showMoreButton && i === limitItemsToRender - 1) && <ShowMoreOverlay productUrl={productUrl}/>}
							{id === "whatsapp-call" ?
								<WhatsappSlide lastImageOfSlider={lastImageOfSlider}/> :
								<Image style={{borderRadius: "8px"}} className={`${showMoreButton && i === limitItemsToRender - 1 ? "blur" : ""}`} src={src} alt={alt} layout={"responsive"} objectFit="cover" width={288} height={288}/>
							}
						</div>
					)
					)}
				</Slider>
				{ hasComparisonBtn &&
				<div className="d-flex justify-content-between px-3 px-md-0 mt-4 pt-2 pt-md-0 mt-md-0">
					{!isAccessory && <div style={{maxWidth: 168}}>
						<ComparisonCheckboxButton bgColor={"#F8F8F8"} textBold borderRadius="16px" slug={bike?.slug} paddingY="py-2" isPDPcomparisonBtn/>
					</div>}
					<div className="d-inline-block px-3 py-2" style={{backgroundColor: "#F8F8F8", cursor: "pointer", borderRadius: 30}}>
						<Link href={"http://api.whatsapp.com/send?phone=41435051318"}>
							<a className="d-flex align-items-center text-decoration-none " target="_blank">
								<Image src={"/assets/icons/whats-app-button-black-icon.svg"} width={24} height={24}/>
								<Typography variant="bodyLgBold" semanticTag="span" className="ms-3" style={{color: "#212529"}}>WhatsApp</Typography>
							</a>
						</Link>
					</div>
				</div> }
			</div>
		</div>)
}

export default CustomSlider
