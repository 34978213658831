import React, {useContext, useState} from "react"
import {Form, Formik} from "formik"
import {formNewsletterHandler} from "../../utility/postHandler"
import {useTranslation} from "next-i18next"
import * as Yup from "yup"
import FormInput, {Color} from "../reusable/FormInput/FormInput"
import {gaCategories, gaEvents} from "../../config/googleAnalytics/events"
import Button from "../reusable/components/Button"
import Typography from "../reusable/Typography/Typography"
import CheckBoxField from "./fields/CheckBoxField"
import {EnvelopeIcon} from "../bikeDetails/sizes/Sizes"
import AnalyticsContext from "../../context/AnalyticsContext"
import {useRouter} from "next/router"

interface MyFormValues {
    firstname: string,
    lastname: string,
    email: string,
	checkboxAgreement: boolean
}

const SizeNotificationForm = () => {
	const {t} = useTranslation()
	const [formSubmitted, setFormSubmitted] = useState(false)
	const [defaultStatus, setDefaultStatus] = useState(200)
	const [isLoading, setIsLoading] = useState(false)
	const {reactGA} = useContext(AnalyticsContext)
	const {locale} = useRouter()

	const instanceId = locale === "de" ? process.env.NEXT_PUBLIC_FORM_NEWSLETTER_DE : locale === "fr" ? process.env.NEXT_PUBLIC_FORM_NEWSLETTER_FR : process.env.NEXT_PUBLIC_FORM_NEWSLETTER_DE

	const initialValues: MyFormValues = {
		firstname: "",
		lastname: "",
		email: "",
		checkboxAgreement: false
	}
	const schema = Yup.object().shape({
		firstname: Yup.string()
			.min(2, t("Too Short!"))
			.max(50, t("Too Long!"))
			.required(t("required")),
		lastname: Yup.string()
			.min(2, t("Too Short!"))
			.max(50, t("Too Long!"))
			.required(t("required")),
		email: Yup.string()
			.email(t("Invalid email"))
			.required(t("required")),
		checkboxAgreement: Yup.boolean()
			.oneOf([true])
			.required(t("required"))
	})

	return (
		<>
			{formSubmitted ?

				<p className={"col-12 text-dark mt-4" }>{t("Vielen Dank für deine Anmeldung beim Newsletter! Neuigkeiten und spezielle Angebote werden nun direkt in dein Postfach gesendet.")}{" "}{"😊📮"}</p> :

				<Formik
					initialValues={initialValues}
					validationSchema={schema}
					onSubmit={async (values, actions) => {
						const obj = {
							formId: process.env.NEXT_PUBLIC_FORM_ID,
							instanceId,
							data: values
						}

						const response = await formNewsletterHandler(obj, setIsLoading)
						// Log event
						reactGA?.event({
							category: gaCategories.newsletter,
							action: gaEvents.newsletterFormSubmitted,
							label: gaEvents.newsletterFormSubmitted,
							nonInteraction: true
						})

						if (response.status === 200) {
							setFormSubmitted(true)
						}

						if (response.status !== 200) {
							setDefaultStatus(response.status)
						}

						actions.setSubmitting(false)
					}}
				>
					{({errors, touched}) => (
						<Form className="col-12">
							{defaultStatus !== 200 && <div className="alert alert-danger mb-4" role="alert">
								{t("Beim Absenden des Formulars ist etwas schiefgelaufen.")}
							</div>}
							<div>
								<div className="d-md-flex justify-content-between">
									<div className="col-12 col-md-6 pe-md-1">
										<FormInput errors={errors}
											label={t("Vorname")}
											touched={touched}
											name={"firstname"}
											color={Color.dark}
										/>
									</div>
									<div className="col-12 col-md-6 ps-md-1">
										<FormInput errors={errors}
											label={t("Nachname")}
											touched={touched}
											name={"lastname"}
											color={Color.dark}
										/>
									</div>
								</div>
								<div>
									<FormInput errors={errors}
										label={t("email")}
										touched={touched}
										name={"email"}
										color={Color.dark}
									/>
								</div>
							</div>

							<div className="col-12 d-flex flex-row flex-start">
								<CheckBoxField name={"checkboxAgreement"}>
									<Typography className="" variant="bodyLg" semanticTag="span">Mit der Auswahl dieser Bedingung akzeptiere ich Informationen im Rahmen des MySofaPlan-Newsletters zu erhalten.</Typography>
								</CheckBoxField>
							</div>

							{(errors && errors.checkboxAgreement && touched.checkboxAgreement) ? (
								<Typography variant="bodyLg" semanticTag="div" className={"text-danger mb-3"}>{t("required")}</Typography>
							) : null}

							<div className="row">
								<div className="col-12">
									<Button
										label={t("newsletter_button_text")}
										outline
										color={"black"}
										className="w-100"
										icon={<EnvelopeIcon/>}
										type={"submit"}
										isLoading={isLoading}
										spinnerColor={"white"}
									/>
								</div>
							</div>

						</Form>

					)

					}
				</Formik>
			}
		</>
	)
}

export default SizeNotificationForm
