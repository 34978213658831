import React from "react"
import Image from "next/image"
import BookAppointmentButton from "../reusable/ModalButtons/BookAppointmentButton"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

type Props = {
  className?: string
	isListView?: boolean
	imageWidth?: number
}

const BookConsultation: React.FC<Props> = ({className, isListView, imageWidth}) => {
	const {t} = useTranslation(["common", "secondHandBikes"])

	return (
		<div className={`container ${className ? className : undefined}`}>
			<div className={`d-flex ${!isListView && "d-md-none"} align-items-center`}>
				<div className="d-flex align-items-center position-relative" style={{top: 0}}>
					<div className="position-absolute d-flex align-items-center" style={{
						width: imageWidth ? (imageWidth + 4) : 52,
						height: imageWidth ? (imageWidth + 4) : 52,
						borderRadius: "50%",
						zIndex: 2,
						backgroundColor: "#fff"
					}}>
						<Image src={isListView ? "/assets/images/consultation-profile-img1-big.png" : "/assets/images/consultation-profile-img1.png"} height={imageWidth ? imageWidth : 48} width={imageWidth ? imageWidth : 48} objectFit="contain"/>
					</div>
					<div className="position-absolute d-flex align-items-center"
						style={{width: imageWidth ? (imageWidth + 4) : 52,
							height: imageWidth ? (imageWidth + 4) : 52, borderRadius: "50%", left: isListView ? 65 : 28}}>
						<Image src={isListView ? "/assets/images/consultation-profile-img2-big.png" : "/assets/images/consultation-profile-img2.png"} height={imageWidth ? imageWidth : 48} width={imageWidth ? imageWidth : 48} objectFit="cover"/>
					</div>
				</div>
				<div style={{marginLeft: isListView ? 170 : 96}}>
					<BookAppointmentButton label={t("Beratung Buchen")} linkWithIcon={true} isListView/>
					<Typography className="mt-2" variant="bodySm">{t("Mit unseren Einrichtungsexperten sprechen")}</Typography>
				</div>
			</div>

			{!isListView &&
			<div className="d-none d-md-grid"><BookAppointmentButton label={t("Beratung Buchen")} high bgColor="#212529" color="#fff" textColor="#fff" outline/>
			</div>
			}
		</div>
	)
}

export default BookConsultation
