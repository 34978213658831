import React from "react"
import PropTypes from "prop-types"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import AnimationComponent from "../reusable/components/AnimationComponent/AnimationComponent"

type Props = {
  isOpen: boolean,
  setIsOpen: any,
  desktop?: boolean,
  label?: string
}

const PDPTooltip: React.FC<Props> = ({isOpen, setIsOpen, desktop, label}) => {
	const {t} = useTranslation()
	// Const [closeBg]

	const desktopClass = "position-absolute bg-white border bottom-0 px-4 pt-3 pb-2 me-5"
	const mobileClass = "position-fixed px-2 py-4 bg-white start-0 bottom-0 end-0"

	const handleClose = () => {
		setIsOpen(false)
	}

	return (
		<>

			<div className="d-none d-md-block">
				{isOpen ? <div
					className={desktop ? desktopClass : mobileClass}
					style={{
						zIndex: 999999
					}}>
					<div className="row">
						<div className="col-12 px-0">
							<div className="d-flex justify-content-between">
								<Typography variant="bodyLg" semanticTag="p">
									{process.env.NEXT_PUBLIC_STOREFRONT === "mysofaplan" ?
										"Bei Schadensfällen hilft dir unser Kundendienst gerne weiter und findet einen geeigneten Servicepartner für dich." :
										t("pdp-tooltip-text")}
								</Typography>
								<div className="ms-auto  cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
									<img src="/assets/icons/close-icon.svg" width="14" height="14"/>
								</div>

							</div>

						</div>
						<div className="d-md-none col-12 text-end">
							<Typography
								variant="bodyLgBold"
								semanticTag="p"
								onClick={() => setIsOpen(!isOpen)}
								className="pointer text-uppercase"
							>
								{t("pdp-tooltip-button-text")}
							</Typography>
						</div>
					</div>
				</div> : null}
			</div>
			<div className="d-block d-md-none" style={{zIndex: 20010}}>
				<div className={`position-fixed top-0 start-0 bottom-0 end-0 bg-light-dark-transparent ${!isOpen && "d-none"}`} style={{
					zIndex: 20002
				}} onClick={() => setIsOpen(!isOpen)}/>
				<AnimationComponent type="slideUp" inProp={isOpen}>
					<div className={"position-fixed px-3 py-4 bg-white start-0 bottom-0 end-0"} style={{
						zIndex: 999999,
						borderRadius: "16px 16px 0px 0px",
						border: "1px solid #f4f4f4"
					}}>
						{label === "service-shipping" ?
							<>
								<div className="row mb-3  justify-content-between align-items-center">
									<div className="col-8">
										<Typography variant="bodyLgBlack" style={{fontSize: 20}}>{t("Kundendienst")}</Typography>
									</div>
									<div className="col-auto cursor-pointer text-end" onClick={() => setIsOpen(!isOpen)}
									>
										<img src="/assets/icons/close-icon.svg" width="15.5" height="15.5"/>
									</div>
								</div>
								<div className="row align-items-center mb-3">
									<img className="col-auto" src="/assets/icons/phone.svg" alt="phone" width="12.21" height="16"/>
									<Typography className="col-8 ps-2" variant="bodyLg">+41 43 505 13 18</Typography>
								</div>
								<div className="row align-items-center  mb-4">
									<img className="col-auto" src="/assets/icons/mail.svg" alt="phone" width="16" height="13"/>
									<Typography className="col-8 ps-2" variant="bodyLg">hello@myhomeplan.ch</Typography>
								</div>
								<div>
									<Typography variant="bodyLgBlack" className="mb-3" semanticTag="h3"
										style={{fontSize: 20}}>{t("Versand")}</Typography>
									<Typography className="mb-0" variant="bodyLg"
										semanticTag="p">{t("Unsere Sofas werden komplett montiert bis vor deine Haustür geliefert. Kostenlose Lieferung in die Schweiz durch unseren Logistikpartner.")}</Typography>
								</div>
							</> :
							label === "financing" ? <>
								<div className="row mb-4 pb-2 justify-content-between align-items-center">
									<div className="col-8">
										<Typography variant="bodyLgBlack" style={{fontSize: 28}}>{t("Finanzierung")}</Typography>
									</div>
									<div className="col-auto cursor-pointer text-end" onClick={() => setIsOpen(!isOpen)}
									>
										<img src="/assets/icons/close-icon.svg" width="15.5" height="15.5"/>
									</div>
								</div>
								<div className="mb-3">
									<Typography variant="bodyLgBlack" style={{fontSize: 20}}
										semanticTag="h2">{t("Unser Konzept")}</Typography>
									<Typography variant="bodyLg"
										semanticTag="p">{t("Kaufe dein E-Bike und mit einer 0% Finanzierung! Wähle deine gewünschte Laufdauer (12-48 Monate) und bestimme so den Monatspreis.")}</Typography>
									<Typography variant="bodyLg" semanticTag="p">{t("Beispiel: CHF 4599 / 48 = CHF 95.80. ")}</Typography>
								</div>
								<div className="mb-3 pt-2">
									<Typography className="mb-3" variant="bodyLgBlack" style={{fontSize: 20}}
										semanticTag="h2">{t("Finanzierung - so funktioniert's")}</Typography>
									<Typography className="mb-1" variant="bodyLg" semanticTag="p">
										{t("1. Lege ein E-Bike in den Warenkorb und wähle die gewünschte Laufdauer.")}
									</Typography>
									<Typography className="mb-1" variant="bodyLg" semanticTag="p">
										{t("2. Unverbindliche Bonitätsprüfung")}
									</Typography>
									<Typography className="mb-1" variant="bodyLg" semanticTag="p">
										{t("3. Vertragsunterzeichnung")}
									</Typography>
									<Typography className="mb-0" variant="bodyLg" semanticTag="p">
										{t("4. Lieferung")}
									</Typography>
								</div>
								<div className="mb-3 pt-2">
									<Typography className="mb-3" variant="bodyLgBlack" style={{fontSize: 20}}
										semanticTag="h2">{t("Flexibler Vertrag")}</Typography>
									<Typography className="mb-1" variant="bodyLg" semanticTag="p">
										{t("Sobald Du den Gesamtpreis bezahlt hast, ist der Vertrag erfüllt. Das bedeutet, Du kannst jederzeit mehr als der vertraglich geregelte Betrag zahlen und so den Vertrag von heute auf morgen beenden.")}
									</Typography>
								</div>
							</> : label === "guarantee" ?
								<>
									<div className="row mb-4 pb-2 justify-content-between align-items-center">
										<div className="col-8">
											<Typography variant="bodyLgBlack" style={{fontSize: 28}}>{t("Garantie")}</Typography>
										</div>
										<div className="col-auto cursor-pointer text-end" onClick={() => setIsOpen(!isOpen)}
										>
											<img src="/assets/icons/close-icon.svg" width="15.5" height="15.5"/>
										</div>
									</div>
									<Typography variant="bodyLg" semanticTag="p"
										className="mb-0">
										{process.env.NEXT_PUBLIC_STOREFRONT === "mysofaplan" ? "Bei Schadensfällen hilft dir unser Kundendienst gerne weiter und findet einen geeigneten Servicepartner für dich." :
											t("Alle unsere Modelle verfügen über 2 Jahre Werksgarantie. Bei Schadensfällen hilft dir unser Kundendienst gerne weiter und findet einen geeigneten Servicepartner für dich oder organisiert die Abholung und Reparatur im Garantiefall.")}
									</Typography>
								</> : null
						}
					</div>

				</AnimationComponent>
			</div>
		</>
	)
}

PDPTooltip.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.any
}

export default PDPTooltip
