import React from "react"
import PropTypes from "prop-types"
import {XLg} from "react-bootstrap-icons"
import Typography from "../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"

type Props = {
	bike:any
	onClose:()=>void
}

const DeliveryAndReturnsSidebar:React.FC<Props> = ({bike, onClose = () => {
	// Do nothing
}}) => {
	const {t} = useTranslation()
	return (
		<div className={"position-fixed top-0 end-0 bottom-0 start-0"}
			onClick={onClose}
			style={{
				backgroundColor: "rgba(0,0,0,0.5)",
				zIndex: 130000
			}}>
			<div onClick={e => e.stopPropagation()}
				className={"position-absolute top-0 end-0 bottom-0 p-4 p-md-0 overflow-auto delivery-and-returns-sidebar-width"}>
				<div className={"bg-white shadow p-4 delivery-and-returns-sidebar-height"}>
					<div className={"row justify-content-end"}>
						<div className={"col-auto"}>
							<button type={"button"} className={"btn"} onClick={onClose}>
								<XLg />
							</button>
						</div>
					</div>
					<Typography variant={"heading3"} className={"mb-4 d-block"} semanticTag={"span"}>
						{t("Kundendienst")}
					</Typography>
					<Typography variant={"bodyLg"} semanticTag={"p"}>
						<img src={"/assets/phone.svg"} className={"me-4"}/>{"+41 43 505 13 18"}
						<br/>
						<br/>
						<img src={"/assets/envelope.svg"} className={"me-4"}/>{"hello@myhomeplan.ch"}
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"} className={"mb-4 d-block"} semanticTag={"span"}>
						{t("Versand")}
					</Typography>
					<Typography variant={"bodyLg"} semanticTag={"p"}>
						{t("Unsere Sofas werden komplett montiert bis vor deine Haustür geliefert. Kostenlose Lieferung in die Schweiz durch unseren Logistikpartner.")}
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"} className={"mb-4 d-block"} semanticTag={"span"}>
						{t("Unser Konzept")}
					</Typography>
					<Typography variant={"bodyLg"} semanticTag={"p"}>
						{t("Kaufe dein E-Bike und mit einer 0% Finanzierung! Wähle deine gewünschte Laufdauer (12-48 Monate) und bestimme so den Monatspreis.")}
						<br/>
						{t("Beispiel: CHF 4599 / 48 = CHF 95.80. ")}
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"} className={"mb-4 d-block"} semanticTag={"span"}>
						{t("Finanzierung - so funktioniert's")}
					</Typography>
					<Typography variant={"bodyLg"} semanticTag={"p"}>
						{t("1. Lege ein E-Bike in den Warenkorb und wähle die gewünschte Laufdauer.")}
						<br/>
						{t("2. Unverbindliche Bonitätsprüfung")}
						<br/>
						{t("3. Vertragsunterzeichnung")}
						<br/>
						{t("4. Lieferung")}
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"} className={"mb-4 d-block"} semanticTag={"span"}>
						{t("Flexibler Vertrag")}
					</Typography>
					<Typography variant={"bodyLg"} semanticTag={"p"}>
						{t("Sobald Du den Gesamtpreis bezahlt hast, ist der Vertrag erfüllt. Das bedeutet, Du kannst jederzeit mehr als der vertraglich geregelte Betrag zahlen und so den Vertrag von heute auf morgen beenden.")}
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"} className={"mb-4 d-block"} semanticTag={"span"}>
						{t("Vertragsstornierung")}
					</Typography>
					<Typography variant={"bodyLg"} semanticTag={"p"}>
						{t("Der Vertrag kann innerhalb 14 Tagen nach Unterzeichnung unkompliziert storniert werden. (Frist beginnt mit Unterschriftsdatum, nicht nach Lieferung)")}
					</Typography>
					<div className={"mt-5 mb-5 border-top"}/>
					<Typography variant={"heading3"} className={"mb-4 d-block"} semanticTag={"span"}>
						{t("Garantie")}
					</Typography>
					<div style={{
						paddingBottom: 140
					}}>
						<Typography variant={"bodyLg"} semanticTag={"p"}>
							{t("Alle unsere Modelle verfügen über 2 Jahre Werksgarantie. Bei Schadensfällen hilft dir unser Kundendienst gerne weiter und findet einen geeigneten Servicepartner für dich oder organisiert die Abholung und Reparatur im Garantiefall.")}
						</Typography>
					</div>
				</div>
			</div>
		</div>
	)
}

DeliveryAndReturnsSidebar.propTypes = {
	bike: PropTypes.any.isRequired,
	onClose: PropTypes.func.isRequired
}

export default DeliveryAndReturnsSidebar
