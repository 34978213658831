import Typography from "../../reusable/Typography/Typography"
import React from "react"
import {useTranslation} from "next-i18next"
import FindPerfectFurnitureCard from "./FindPerfectFurnitureCard"

const FindPerfectFurniture = () => {
	const {t} = useTranslation()

	return (
		<div className="col-12 container mb-5 pb-3 mb-md-0 pb-md-0 mt-3 mt-md-5 find-perfect-furniture">
			<Typography variant={"heading2Black"} semanticTag="h1"
				className={"text-center mb-5 d-none d-md-block"}>{t("find-perfect-furniture-title")}</Typography>
			<Typography variant={"heading2"} semanticTag="h2"
				className={"text-start mb-4 pb-2 d-md-none"} style={{lineHeight: "36px"}}>{t("find-perfect-furniture-title")}</Typography>

			<div className="d-md-flex justify-content-center" style={{columnGap: 40}}>
				<div className="col-12 col-md-4 mb-4 mb-md-0">
					<FindPerfectFurnitureCard
						imageSrc={"/assets/images/take-quiz-img.png"}
						title={t("custom-offer-card-title")}
						description={t("take-short-quiz")}
						btnLabel={t("take-quiz-btn")}
						btnIconSrc={"/assets/icons/question-mark-box.svg"}
						btnHref={"https://www.myplans.ch/whatsapp-suggestions-my-sofa-plan"}
						titleWidth={"65%"}
					/>
				</div>
				<div className="col-12 col-md-4">
					<FindPerfectFurnitureCard
						imageSrc={"/assets/images/book-consultation-img.png"}
						title={t("get-contacted-card-title")}
						description={t("talk-to-our-experts-card-desc")}
						isConsultationBtn
						titleWidth="95%"
					/>
				</div>
				<div className="col-12 col-md-4">
					<FindPerfectFurnitureCard
						imageSrc={"/assets/images/check-credit-score-img.png"}
						title={t("eligible-for-financing-title")}
						description={t("eligible-for-financing-desc")}
						titleWidth="95%"
						btnLabel={t("check-score-btn")}
						btnIconSrc={"/assets/icons/credit-check-icon.svg"}
						btnHref={"https://www.myplans.ch/organic-credit-check-my-sofa-plan"}
					/>
				</div>
			</div>
		</div>
	)
}

export default FindPerfectFurniture
