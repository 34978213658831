import React, {useContext, useEffect, useMemo, useState} from "react"
import Slider from "../../slider/Slider"
import CheckoutContext from "../../../context/CheckoutContext"
import Typography from "../../reusable/Typography/Typography"
import {useTranslation} from "next-i18next"
import {clampValue} from "../../../utility/Helper"
import CartContext, {CartItem} from "../../../context/CartContext"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {useRouter} from "next/router"

type Props = {
	isSecondHand?: boolean;
	bikeId?: number | null;
	min: number;
	max: number;
	IsPDPDownpayment?: boolean
	hideLabel?: boolean;
	inputWidth?: string;
	isPLP?: boolean;
	showPriceTooltip?: boolean;
	hasPlaceholder?: boolean;
	isHorizontalDownpayment?: boolean
	PriceInputBesidesTitle?: boolean
	isTitleUppercase?: boolean
};

function useLocalStorage(key: string, initialValue: any, max:number) {
	const [value, setValue] = useState(() => {
		if (typeof window !== "undefined") {
			const storedValue = localStorage.getItem(key)
			return storedValue && Number(storedValue) < max ? JSON.parse(storedValue) : Number(storedValue) > max ? max : initialValue
		}

		return initialValue
	})

	useEffect(() => {
		if (typeof window !== "undefined") {
			localStorage.setItem(key, JSON.stringify(value))
			window.dispatchEvent(new Event("storage"))
		}
	}, [key, value])

	return [value, setValue]
}

const DownPayment: React.FC<Props> = ({
	bikeId = null,
	min,
	max,
	hideLabel,
	isPLP = false,
	showPriceTooltip = false,
	hasPlaceholder = true,
	IsPDPDownpayment,
	isHorizontalDownpayment = false,
	PriceInputBesidesTitle = false,
	isTitleUppercase
}) => {
	const {data, setData} = useContext(CheckoutContext)
	const {items, setItems} = useContext(CartContext)
	const {reactGA} = useContext(AnalyticsContext)
	const checkoutData = data
	const setCheckoutData = setData
	const {t} = useTranslation("common")
	const [updateTimeout, setUpdateTimeout] = useState(null)
	const [sliderValue, setSliderValue] = useLocalStorage("totalDownPayment", min, max)
	const step = 10
	const router = useRouter()

	const [inputValue, setInputValue] = useLocalStorage("totalDownPayment", min, max)
	useEffect(() => {
		if (inputValue) {
			handleUpdate(inputValue)
		} else {
			handleUpdate(0)
		}
	}, [])
	const defaultValue = useMemo(() => {
		if (checkoutData?.downPayment) {
			if (bikeId) {
				return checkoutData?.downPayment[bikeId]
			}

			return parseFloat(localStorage.getItem("totalDownPayment")) || min
		}

		if (typeof window !== "undefined") {
			return parseFloat(localStorage.getItem("totalDownPayment")) || min
		}

		return min
	}, [checkoutData, items])

	const totalCartValue = useMemo(
		() =>
			items.reduce(
				(cartValue, item) => cartValue + parseFloat(item.product.finalPriceWithoutDownPayment || item.product.price),
				0
			),
		[items]
	)

	const handleOnChange = (value:number) => {
		const clampedValue = clampValue(value, min, max)
		setSliderValue(clampedValue)
		setInputValue(clampedValue)
	}

	const handleUpdate = (value: number) => {
		if (updateTimeout) {
			clearTimeout(updateTimeout)
		}

		const clampedValue = clampValue(value, min, max)

		setUpdateTimeout(
			setTimeout(() => {
				if (bikeId) {
					setCheckoutData(prevData => ({
						...prevData,
						downPayment: {
							...prevData?.downPayment,
							[bikeId]: clampedValue
						}
					}))
				} else {
					setItems((prevItems: CartItem[]) => prevItems.map(prevItem => {
						const ratioToCartValue = prevItem.product.finalPriceWithoutDownPayment / totalCartValue
						return {
							...prevItem,
							downPayment: clampedValue * ratioToCartValue
						}
					}))
					// SetCheckoutData(prevData => ({
					// 	...prevData,
					// 	deals: prevData.deals.map(deal => {
					// 		const ratioToCartValue = deal.e_bike_price / totalCartValue
					// 		return {
					// 			...deal,
					// 			// eslint-disable-next-line camelcase
					// 			down_payment_amount: clampedValue * ratioToCartValue
					// 		}
					// 	})
					// }))
				}
			}, 1)
		)
	}

	const handleDownPaymentGAEvent = (clampValue: number) => {
		setTimeout(() => {
			reactGA?.event({
				category:
					router.pathname === "/checkout" ? gaCategories.checkout : gaCategories.pdp,
				action: gaEvents.downPaymentValue,
				label: clampValue,
				nonInteraction: false
			})
		}, 500)
	}

	useEffect(() => {
		if (checkoutData?.planLength === 1) {
			handleUpdate(0)
		}
	}, [checkoutData?.planLength])

	return (
		<div className={`h-100 ${PriceInputBesidesTitle ? "d-flex flex-column justify-content-between" : IsPDPDownpayment ? "d-flex align-items-center justify-content-between" : ""}`}>
			{IsPDPDownpayment && !hideLabel && (
				<Typography variant="bodySmBold" semanticTag="span" className={isTitleUppercase ? "text-uppercase" : "col-auto"}>
					{t("Downpayment")}
				</Typography>
			)}
			<div className="col-auto d-flex justify-content-between align-items-center">
				{PriceInputBesidesTitle && !hideLabel && (
					<Typography variant="bodySmBold" semanticTag="span" className={isTitleUppercase ? "text-uppercase" : "col-auto"}>
						{t("Downpayment")}
					</Typography>
				)}
				{PriceInputBesidesTitle &&
					<div
						className="d-inline-flex align-items-center downpayment-input-wrapper col-5"
					>
						{hasPlaceholder && (
							<Typography variant="bodySm" className="me-1">
								CHF
							</Typography>
						)}
						<input
							disabled={data?.planLength === 1 || false}
							type="number"
							value={inputValue}
							className={"downpayment-input"}
							onChange={e => {
								e.preventDefault()
								if (e.target.value.startsWith("0")) {
									e.target.value = e.target.value.slice(1, e.target.value.length)
								}

								const newValue = parseFloat(e.target.value)
								handleOnChange(newValue || 0)
								handleUpdate(newValue || 0)
								handleDownPaymentGAEvent(clampValue(newValue, min, max))
							}}
						/>
					</div>
				}
			</div>
			<div className={` ${isHorizontalDownpayment || IsPDPDownpayment ? "d-flex mt-2 col-9" : "d-flex flex-column flex-column-reverse col-12"} justify-content-between align-items-center`}>
				<div className={`${isHorizontalDownpayment ? "col-7" : IsPDPDownpayment ? "col-8" : "col-12 mt-2 pt-1"}`}>
					<Slider
						disabled={data?.planLength === 1 || false}
						paymentValueOne={inputValue}
						hasPriceTooltip={showPriceTooltip}
						defaultValue={sliderValue}
						min={min}
						max={max}
						step={step}
						onChange={value => {
							handleOnChange(value)
						}}
						onAfterChange={value => {
							handleUpdate(value)
							handleDownPaymentGAEvent(clampValue(value, min, max))
						}}
					/>
				</div>
				<div
					className={`d-inline-flex align-items-center downpayment-input-wrapper ${isHorizontalDownpayment ? "col-4" : IsPDPDownpayment ? "col-3" : "col-12"}  ${PriceInputBesidesTitle ? "d-none" : ""}`}
				>
					{hasPlaceholder && (
						<Typography variant="bodySm" className="me-1">
                CHF
						</Typography>
					)}
					<input
						disabled={data?.planLength === 1 || false}
						type="number"
						value={inputValue}
						className={"downpayment-input"}
						onChange={e => {
							e.preventDefault()
							if (e.target.value.startsWith("0")) {
								e.target.value = e.target.value.slice(1, e.target.value.length)
							}

							const newValue = parseFloat(e.target.value)
							handleOnChange(newValue || 0)
							handleUpdate(newValue || 0)
							handleDownPaymentGAEvent(clampValue(newValue, min, max))
						}}
					/>
				</div>
			</div>
		</div>
	)
}

DownPayment.propTypes = {}

export default DownPayment
